import { CustomerPurchases } from '~/src/Model/Purchase/Purchase'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export enum ParameterAvailableAction {
  Review = 'review',
  AfterSale = 'afterSale'
}

export interface FilterParameters {
  availableAction: string | null
  dateFrom: string | null
  dateTo: string | null
  priceFrom: string | null
  priceTo: string | null
  productName: string | null
  purchaseNumber: string | null
  purchaseType: string | null
}

export interface Parameters extends FilterParameters {
  onlyActive: number
  excludeActive: number
  page: number
  limit: number
}

export const emptyFilterParameters = (): FilterParameters => {
  return {
    availableAction: null,
    dateFrom: null,
    dateTo: null,
    priceFrom: null,
    priceTo: null,
    productName: null,
    purchaseNumber: null,
    purchaseType: null
  }
}

export const useCustomerPurchasesRepository = () => {
  const { $axios } = useAxios()

  return {
    loadPurchases: (customerId: number, parameters: Parameters): Promise<CustomerPurchases> => {
      return $axios.$get<CustomerPurchases>(`/customers/${customerId}/purchases`, {
        params: parameters
      })
    }
  }
}
