import { ref, useFetch, watch, computed } from '@nuxtjs/composition-api'
import { HomePage, HOMEPAGE_ID } from '~/src/Model/HomePage/HomePage'
import { useHomePageRepository } from '~/src/Infrastructure/HomePage/HomePageRepository'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import { useRecommendedProductsFetch } from '~/src/Infrastructure/RecommendedProducts/RecommendedProductsFetch'
import { useLoyaltyProductsFetch } from '~/src/Infrastructure/LoyaltyProducts/LoyaltyProductsFetch'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'

export const useHomePage = () => {
  const PRODUCTS_LIMIT = 6
  const { loadHomePage } = useHomePageRepository()
  const { homepageMinimumLoyaltyProductsToShow } = useWebConfig()
  const { canSeeLoyaltyPrices } = useAuthenticatedUser()

  const homePage = ref<HomePage | null>(null)
  const homePageLoaded = ref<boolean>(false)

  const { recommendedProducts, loading: recommendedProductsLoading } = useRecommendedProductsFetch(HOMEPAGE_ID, PRODUCTS_LIMIT)
  const { loyaltyProducts, loading: loyaltyProductsLoading, exec: loadLoyaltyProducts } = useLoyaltyProductsFetch(PRODUCTS_LIMIT, 1, true)

  const { exec: execLoadHomePage, loading: homePageLoading, onSuccess: onHomePageLoaded } = usePromise(async () => {
    homePage.value = await loadHomePage()
    homePageLoaded.value = true
  })

  useFetch(execLoadHomePage)

  const showLoyaltyProducts = computed<boolean>(() => {
    if (!homePage.value) {
      return false
    }

    if ((homePage.value?.loyaltyProducts?.totalCount ?? 0) < homepageMinimumLoyaltyProductsToShow) {
      return false
    }

    if (!canSeeLoyaltyPrices.value) {
      return false
    }

    return true
  })

  watch(homePage, async () => {
    if (!showLoyaltyProducts.value) {
      return
    }

    await loadLoyaltyProducts()
  }, { immediate: true })

  return {
    homePage,
    homePageLoading,
    homePageLoaded,
    onHomePageLoaded,
    recommendedProducts,
    recommendedProductsLoading,
    loyaltyProductsLoading,
    loyaltyProducts,
    showLoyaltyProducts
  }
}
