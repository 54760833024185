






































































































































































































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import PurchaseDetailProductBundles
  from '~/components/Profile/Purchases/PurchaseDetailProductBundle/PurchaseDetailProductBundles.vue'
import PurchaseDetailProductWithGifts
  from '~/components/Profile/Purchases/PurchaseDetailProduct/PurchaseDetailProductWithGifts.vue'
import CustomerBillingInfo from '~/components/Order/Resume/Components/CustomerBillingInfo.vue'
import CustomerCompanyInfo from '~/components/Order/Resume/Components/CustomerCompanyInfo.vue'
import { useOrderDetailFetch } from '~/src/Infrastructure/Order/OrderDetailFetch'
import SectionTitle from '~/components/Order/SectionTitle/SectionTitle.vue'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import { Type } from '~/src/Model/Purchase/Purchase'
import OrderOtherItemsList from '~/components/Order/Resume/Components/OrderOtherItemsList.vue'
import { bigify } from '~/src/Infrastructure/Number/Bigify/Bigify'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import { PurchaseBundleInfo, PurchaseInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import PrintLink from '~/components/PrintLink/PrintLink.vue'
import OrderReverseChargeInfo from '~/components/Order/ReverseCharge/OrderReverseChargeInfo.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import CopyButton from '~/components/DesignSystem/Buttons/CopyButton.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import ComfortDeliverySelectedSlotInfo
  from '~/components/Order/Delivery/DeliveryMethod/ComfortDelivery/ComfortDeliverySelectedSlotInfo.vue'
import { PayMethodType } from '~/src/Model/Payment/PayMethod'

interface PrintData {
  path: string
  text: string
}

export default defineComponent({
  components: {
    OrderReverseChargeInfo,
    PurchaseDetailProductBundles,
    PurchaseDetailProductWithGifts,
    SectionTitle,
    CustomerBillingInfo,
    CustomerCompanyInfo,
    OrderOtherItemsList,
    PrintLink,
    Row,
    Column,
    Button,
    CopyButton,
    Alert,
    ComfortDeliverySelectedSlotInfo
  },
  props: {
    purchaseNumber: {
      required: true,
      type: Number
    },
    purchaseType: {
      required: true,
      type: String as PropType<Type>
    }
  },
  emits: ['open-storno'],
  setup (props) {
    const { i18n } = useTranslator()
    const { order } = useOrderDetailFetch(props.purchaseNumber)
    const systemPagesStore = useSystemPagesStore()
    const printData = computed<PrintData>(() => {
      if (props.purchaseType === Type.Reservation) {
        return {
          path: systemPagesStore.pathByUid('PRINT_RESERVATION').value,
          text: i18n.t('nayeshop_purchases.detail.print_reservation') as string
        }
      }

      return {
        path: systemPagesStore.pathByUid('ORDER_PRINT').value,
        text: i18n.t('nayeshop_purchases.detail.print_order') as string
      }
    })

    const paidStatus = computed<string>(() => {
      if (order.value === null) {
        return ''
      }

      return order.value.paid
        ? i18n.t('nayeshop_purchases.detail.is_paid') as string
        : i18n.t('nayeshop_purchases.detail.not_paid') as string
    })

    const showOthersListSection = computed<boolean>(() => {
      if (!order.value) {
        return false
      }

      if (bigify(order.value.creditsUsed).gt(0) || order.value.vouchers.length > 0 || order.value.donations.length > 0 || order.value.valueVouchers.length > 0) {
        return true
      }

      return false
    })

    const scaffoldPurchaseInfo = (productId: number): PurchaseInfo => {
      return {
        number: props.purchaseNumber,
        type: props.purchaseType,
        itemId: productId,
        purchaseDate: order.value?.created ?? ''
      }
    }

    const scaffoldPurchaseBundleInfo = computed<PurchaseBundleInfo>(() => ({
      number: props.purchaseNumber,
      type: props.purchaseType,
      purchaseDate: order.value?.created ?? ''
    }))

    const productsInPurchaseText = computed<string>(() => {
      if (props.purchaseType === Type.Order) {
        return i18n.t('nayeshop_purchases.detail.products_in_order') as string
      } else if (props.purchaseType === Type.Reservation) {
        return i18n.t('nayeshop_purchases.detail.products_in_reservation') as string
      } else {
        return i18n.t('nayeshop_purchases.detail.products_in_receipt') as string
      }
    })

    const showPaymentStatus = computed<boolean>(() => {
      if (!order.value) {
        return false
      }
      // @ts-ignore
      return [PayMethodType.online, PayMethodType.installmentsBridge, PayMethodType.homeCredit].includes(order.value!.payMethod.type)
    })

    return {
      order,
      paidStatus,
      printData,
      showOthersListSection,
      scaffoldPurchaseInfo,
      scaffoldPurchaseBundleInfo,
      productsInPurchaseText,
      PayMethodType,
      showPaymentStatus
    }
  }
})
