













































































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { useOrderRepository } from '~/src/Infrastructure/Order/OrderRepository'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import { Type } from '~/src/Model/Purchase/Purchase'
import { StornoReason } from '~/src/Model/Storno/Storno'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'
import { useLocalFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/LocalFlashMessageCenter'
import { useTranslatedFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/TranslatedFlashMessageCenter'
import { useApiErrorFactory } from '~/src/Infrastructure/Api/ApiErrorFactory'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { RadioGroupOption } from '~/src/Model/Form/Input/RadioGroupOption'
import RadioGroup from '~/components/DesignSystem/Input/RadioGroup.vue'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import LoadingOverlay from '~/components/Loading/LoadingOverlay.vue'
import FormTextarea from '~/components/DesignSystem/Input/FormTextarea.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

const CUSTOMER_REASON_LENGTH_LIMIT = 3900

export default defineComponent({
  components: {
    FlashMessageCenter,
    RadioGroup,
    BaseValidationObserver,
    LoadingOverlay,
    FormTextarea,
    Button,
    Alert,
    PageSectionContent
  },
  props: {
    purchaseType: {
      required: true,
      type: String as PropType<Type>
    },
    purchaseNumber: {
      required: true,
      type: Number
    }
  },
  emits: ['cancel', 'storno-successful'],
  setup (props, { emit }) {
    const { i18n } = useTranslator()
    const { cancelOrder } = useOrderRepository()
    const { flashMessageCenter, flashMessages } = useLocalFlashMessageCenter()
    const translatedFlashMessageCenter = useTranslatedFlashMessageCenter(flashMessageCenter)
    const { apiErrorFactory } = useApiErrorFactory()

    const selectedReason = ref<StornoReason | null>(null)
    const customerReason = ref<string>('')
    const stornoFailed = ref<boolean>(false)
    const optionError = ref<boolean>(false)

    const reasonOptions: RadioGroupOption[] = [
      {
        value: StornoReason.OTHER_PRODUCT,
        label: i18n.t('nayeshop_purchases.storno.other_product_reason') as string
      },
      {
        value: StornoReason.BETTER_PRICE,
        label: i18n.t('nayeshop_purchases.storno.better_price_reason') as string
      },
      {
        value: StornoReason.NO_WAIT,
        label: i18n.t('nayeshop_purchases.storno.no_wait_reason') as string
      },
      {
        value: StornoReason.UNSPECIFIED,
        label: i18n.t('nayeshop_purchases.storno.unspecified_reason') as string
      },
      {
        value: StornoReason.OTHER,
        label: i18n.t('nayeshop_purchases.storno.other_reason') as string
      }
    ]

    const selectReason = (newValue: StornoReason) => {
      selectedReason.value = newValue
    }

    const { exec: confirmPurchaseStorno, loading } = usePromise(async () => {
      optionError.value = false
      translatedFlashMessageCenter.clear()

      if (selectedReason.value === null) {
        optionError.value = true
        return
      }

      try {
        await cancelOrder(props.purchaseNumber, {
          reason: selectedReason.value,
          customerReason: selectedReason.value === StornoReason.OTHER ? customerReason.value : null
        })
        emit('storno-successful')
      } catch (e: any) {
        stornoFailed.value = true
        const apiError = apiErrorFactory(e)
        translatedFlashMessageCenter.warning(apiError.translatedMessage, apiError.internalErrorCode)
      }
    })

    return {
      ORDER_PURCHASE_TYPE: Type.Order,
      selectedReason,
      StornoReason,
      selectReason,
      reasonOptions,
      confirmPurchaseStorno,
      customerReason,
      flashMessages,
      stornoFailed,
      loading,
      optionError,
      CUSTOMER_REASON_LENGTH_LIMIT
    }
  }
})
