import { ref, useFetch } from '@nuxtjs/composition-api'
import { useDigitalProductPayMethodsRepository } from '~/src/Infrastructure/DigitalProductPayMethods/DigitalProductPayMethodsRepository'
import { PayMethod } from '~/src/Model/Order/Delivery/PayMethod/PayMethod'

export const useDigitalProductPayMethodFetch = () => {
  const payMethods = ref<PayMethod[]>([])
  const { loadPayMethods } = useDigitalProductPayMethodsRepository()

  useFetch(async () => {
    const response = await loadPayMethods()

    payMethods.value = response.payMethods.map(p => new PayMethod(p))
  })

  return {
    payMethods
  }
}
