import {
  DigitalProductOrderCustomerInfo, emptyDigitalProductCustomerFormInfo
} from '~/src/Model/DigitalProductOrder/DigitalProductOrder'
import { CustomerInformation } from '~/src/Model/Customer/CustomerInformation'

export const createDigitalOrderCustomerFormInfo = (customerInfo: CustomerInformation, countryCode: string): DigitalProductOrderCustomerInfo => {
  const defaultBillingAddress = customerInfo.addresses.billing.find(address => address.isDefault) ?? null

  if (!defaultBillingAddress) {
    return emptyDigitalProductCustomerFormInfo()
  }

  return {
    email: customerInfo.email.value ?? '',
    phone: customerInfo.phone ?? '',
    firstName: defaultBillingAddress.firstName,
    lastName: defaultBillingAddress.lastName,
    billingAddress: {
      street: defaultBillingAddress.street,
      city: defaultBillingAddress.city,
      zip: defaultBillingAddress.zip,
      addressNote: null,
      countryCode
    },
    companyInfo: defaultBillingAddress.companyInfo
  }
}
