

























































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import ArticleInfo from '~/components/Magazine/ArticleInfo.vue'
import { ArticleThumbnail } from '~/src/Model/Magazine/Magazine'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import TextSkeleton from '~/components/DesignSystem/Skeleton/TextSkeleton.vue'
import Badge from '~/components/DesignSystem/Badge/Badge.vue'

export default defineComponent({
  components: {
    ArticleInfo,
    ImageWithFallback,
    TextSkeleton,
    Badge
  },
  props: {
    article: {
      required: true,
      type: Object as PropType<ArticleThumbnail>
    },
    small: {
      type: Boolean,
      default: false
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  setup (props) {
    const sizes = props.small
      ? '(min-width: 1340px) 300px,' +
        '(min-width: 992px) calc((100vw - 2 * 30px - 2 * 1.25rem - 3 * 20px) / 4),' +
        '(min-width: 768px) calc((100vw - 2 * 0.9375rem - 2 * 20px) / 3),' +
        'calc((100vw - 2 * 0.9375rem - 20px) / 2)'

      : '(min-width: 1340px) 360px,' +
        '(min-width: 992px) calc((100vw - 2 * 30px - 2 * 10px) / 3 - 2 * 1.875rem),' +
        '(min-width: 768px) calc((100vw - 2 * 10px) / 3 - 2 * 0.9375rem),' +
        '(min-width: 576px) calc((100vw - 10px) / 2 - 2 * 0.9375rem),' +
        'calc(100vw - 2 * 0.9375rem)'

    return { sizes }
  }
})
