


































































import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { Order } from '~/src/Model/Order/Order'
import SideBasketItemContent from '~/components/Basket/SideBasket/SideBasketItemContent.vue'
import SectionTitle from '~/components/Order/SectionTitle/SectionTitle.vue'
import { bigify } from '~/src/Infrastructure/Number/Bigify/Bigify'
import OrderVoucherModal from '~/components/Order/Resume/Components/OrderVoucherModal.vue'

export default defineComponent({
  components: {
    SideBasketItemContent,
    SectionTitle,
    OrderVoucherModal
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    }
  },
  setup (props) {
    const creditsWereUsed = computed<boolean>(() => bigify(props.order.creditsUsed).gt(0))
    const showVoucherModal = ref<boolean>(false)

    return {
      creditsWereUsed,
      showVoucherModal
    }
  }
})
