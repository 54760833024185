import RecommendedProducts from '~/src/Model/RecommendedProducts/RecommendedProducts'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useRecommendedProductsRepository = () => {
  const { $axios } = useAxios()

  return {
    loadRecommendedProducts: (id: number, limit: number, page: number, ignoredAvailability: boolean | null = null): Promise<RecommendedProducts> => $axios.$get<RecommendedProducts>(`/pages/${id}/recommended-products`, { params: { limit, page, ignoredAvailability } })
  }
}
