














import { defineComponent } from '@nuxtjs/composition-api'
import { LayoutOrderType } from '~/src/Model/Layout/LayoutOrder'
import { LayoutOrderVariant } from '~/src/Model/Layout/LayoutOrderVariant'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'

interface PropTypes {
  layoutType: string
}

export default defineComponent<PropTypes>({
  components: { Row, Column },
  props: {
    layoutType: {
      required: true,
      type: String
    }
  },
  setup () {
    const layoutClass = {
      basket: 'align-items-lg-start bs-ml-md-down-0',
      delivery: 'align-items-lg-start',
      address: 'align-items-lg-start',
      reservation: 'd-md-down-contents align-items-lg-start',
      reservationResume: 'd-flex flex-column flex-lg-row bs-ml-md-down-0'
    }

    const contentClass = {
      basket: '',
      delivery: '',
      address: '',
      reservation: 'd-md-down-contents bs-px-md-down-0',
      reservationResume: 'd-lg-flex flex-column'
    }

    const sideClass = {
      basket: '',
      delivery: 'd-lg-flex flex-column',
      address: 'd-lg-flex flex-column',
      reservation: 'd-md-down-contents d-lg-flex flex-column',
      reservationResume: 'd-lg-flex flex-column'
    }

    const layoutVariant: { [key in LayoutOrderType] : LayoutOrderVariant } = ({
      [LayoutOrderType.basket]: LayoutOrderVariant.basket,
      [LayoutOrderType.delivery]: LayoutOrderVariant.delivery,
      [LayoutOrderType.address]: LayoutOrderVariant.address,
      [LayoutOrderType.reservation]: LayoutOrderVariant.reservation,
      [LayoutOrderType.reservationResume]: LayoutOrderVariant.reservationResume
    })

    const side: { [key in LayoutOrderType] : string } = ({
      [LayoutOrderType.basket]: sideClass.basket,
      [LayoutOrderType.delivery]: sideClass.delivery,
      [LayoutOrderType.address]: sideClass.address,
      [LayoutOrderType.reservation]: sideClass.reservation,
      [LayoutOrderType.reservationResume]: sideClass.reservationResume
    })

    const layout: { [key in LayoutOrderType] : string } = ({
      [LayoutOrderType.basket]: layoutClass.basket,
      [LayoutOrderType.delivery]: layoutClass.delivery,
      [LayoutOrderType.address]: layoutClass.address,
      [LayoutOrderType.reservation]: layoutClass.reservation,
      [LayoutOrderType.reservationResume]: layoutClass.reservationResume
    })

    const content: { [key in LayoutOrderType] : string } = ({
      [LayoutOrderType.basket]: contentClass.basket,
      [LayoutOrderType.delivery]: contentClass.delivery,
      [LayoutOrderType.address]: contentClass.address,
      [LayoutOrderType.reservation]: contentClass.reservation,
      [LayoutOrderType.reservationResume]: contentClass.reservationResume
    })

    return {
      layoutVariant,
      layout,
      content,
      side
    }
  }
})
