import { IVueI18n } from 'vue-i18n'
import { FlashMessageCenter } from '~/src/Model/FlashMessage/FlashMessageCenter'
import { FlashMessageType } from '~/src/Model/FlashMessage/FlashMessageType'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'

export class TranslatedFlashMessageCenter implements FlashMessageCenter {
  constructor (
    private readonly notificationCenter: FlashMessageCenter,
    private readonly vueI18n: IVueI18n
  ) {
  }

  success (message: string, code?: number): void {
    this.notificationCenter.success(this.vueI18n.t(message).toString(), code)
  }

  info (message: string, code?: number): void {
    this.notificationCenter.info(this.vueI18n.t(message).toString(), code)
  }

  warning (message: string, code?: number): void {
    this.notificationCenter.warning(this.vueI18n.t(message).toString(), code)
  }

  error (message: string, code?: number): void {
    this.notificationCenter.error(this.vueI18n.t(message).toString(), code)
  }

  message (message: string, type: FlashMessageType, code?: number): void {
    this.notificationCenter.message(this.vueI18n.t(message).toString(), type, code)
  }

  clear (): void {
    this.notificationCenter.clear()
  }

  get messages () {
    return this.notificationCenter.messages
  }
}

export const useTranslatedFlashMessageCenter = (flashMessageCenter: FlashMessageCenter): FlashMessageCenter => {
  const { app } = useApp()

  return new TranslatedFlashMessageCenter(flashMessageCenter, app.i18n as IVueI18n)
}
