

































































































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { OrderProductItem, OrderProductBundleItem, AfterSaleParentPurchase } from '~/src/Model/Order/Order'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import { PurchaseInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import ProductNameLink from '~/components/Basket/Deprecated/ProductNameLink.vue'
import ProductImage from '~/components/Basket/Deprecated/ProductImage.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Badge from '~/components/DesignSystem/Badge/Badge.vue'
import ProductNameLinkAfterSale from '~/components/Basket/Components/ProductNameLinkAfterSale.vue'
import PurchaseDetailGenericItem
  from '~/components/Profile/Purchases/PurchaseDetailProduct/PurchaseDetailGenericItem.vue'
import NayOnly from '~/components/Web/NayOnly.vue'

export default defineComponent({
  components: {
    NayOnly,
    PurchaseDetailGenericItem,
    ProductNameLinkAfterSale,
    ProductNameLink,
    ProductImage,
    Row,
    Column,
    Button,
    Badge
  },
  props: {
    productItem: {
      type: Object as PropType<OrderProductItem | OrderProductBundleItem>,
      required: true
    },
    purchaseInfo: {
      type: Object as PropType<PurchaseInfo>,
      required: true
    },
    afterSaleParentPurchase: {
      type: Object as PropType<AfterSaleParentPurchase | null>,
      default: null
    }
  },
  emits: ['show-reclamation'],
  setup () {
    const systemPagesStore = useSystemPagesStore()
    const productOfferPath = systemPagesStore.pathByUid('productOffer')

    const getProductLink = (token: string): string => {
      return productOfferPath.value + '?purchaseIdentification=' + token
    }

    return {
      getProductLink
    }
  }
})
