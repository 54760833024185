


























































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import { OrderProductItem, OrderProductBundleItem } from '~/src/Model/Order/Order'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import { PurchaseInfo, PurchasedProduct } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import NewReclamationCrossroadModal from '~/components/Reclamation/NewReclamationCrossroad/NewReclamationCrossroadModal.vue'
import ProductNameLink from '~/components/Basket/Deprecated/ProductNameLink.vue'
import ProductImage from '~/components/Basket/Deprecated/ProductImage.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Badge from '~/components/DesignSystem/Badge/Badge.vue'

export default defineComponent({
  components: {
    ImageWithFallback,
    ProductNameLink,
    ProductImage,
    NewReclamationCrossroadModal,
    Row,
    Column,
    Button,
    Badge
  },
  props: {
    productItem: {
      type: Object as PropType<OrderProductItem | OrderProductBundleItem>,
      required: true
    },
    purchaseInfo: {
      type: Object as PropType<PurchaseInfo>,
      required: true
    },
    tag: {
      type: String,
      default: 'section'
    }
  },

  setup (props) {
    const systemPagesStore = useSystemPagesStore()
    const productOfferPath = systemPagesStore.pathByUid('productOffer')

    const showReclamationModal = ref<boolean>(false)

    const getPurchasedProduct = (): PurchasedProduct => {
      return {
        product: props.productItem.product,
        purchaseInfo: props.purchaseInfo,
        reclamation: props.productItem.reclamation
      } as PurchasedProduct
    }

    const getProductLink = (token: string): string => {
      return productOfferPath.value + '?purchaseIdentification=' + token
    }

    return {
      getProductLink,
      showReclamationModal,
      getPurchasedProduct
    }
  }
})
