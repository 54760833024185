







































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import { FlashMessage } from '~/src/Model/FlashMessage/FlashMessage'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'
import LoginRow from '~/components/Auth/LoginRow.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Form from '~/components/DesignSystem/Forms/Form.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'

export default defineComponent({
  components: {
    LoginRow,
    FlashMessageCenter,
    BaseValidationObserver,
    Button,
    Form,
    Alert,
    Modal
  },
  props: {
    loginRequired: {
      type: Boolean,
      default: false
    },
    orderPending: {
      type: Boolean,
      default: false
    },
    flashMessages: {
      type: Array as PropType<FlashMessage[]>,
      default: () => []
    }
  },
  emits: ['close-modal', 'form-submitted'],
  setup (_props, { emit }) {
    const { authenticatedUserId } = useAuthenticatedUser()

    const submit = () => {
      emit('form-submitted')
    }

    return {
      authenticatedUserId,
      submit
    }
  }
})
