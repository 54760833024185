import { ref, useFetch } from '@nuxtjs/composition-api'
import { ExpressPickupTimeSlotsRequest } from '~/src/Model/ExpressPickup/ExpressPickup'
import { useExpressPickupRepository } from '~/src/Infrastructure/ExpressPickup/ExpressPickupRepository'
import { DeliveryDays } from '~/src/Model/ComfortDelivery/DeliveryDays'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { DeliveryType } from '~/src/Model/Delivery/DeliveryMethods'

export const useExpressPickupProductTimeSlots = (productId: number, request: ExpressPickupTimeSlotsRequest) => {
  const expressPickupDays = ref<DeliveryDays | null>(null)
  const { getProductAvailableTimeSlots } = useExpressPickupRepository()

  const timeSlotsLoadFailed = ref<boolean>(false)

  const { exec: loadAvailableTimeSlots, loading: timeSlotsLoading, onError: onTimeSlotsLoadError } = usePromise(async () => {
    timeSlotsLoadFailed.value = false
    expressPickupDays.value = await getProductAvailableTimeSlots(productId, request)
  })

  useFetch(() => {
    if (![DeliveryType.iDoDoBigBoxUrbantz, DeliveryType.iDoDoSmallBoxUrbantz, DeliveryType.nayExpressHdw, DeliveryType.nayExpressHds].includes(request.deliveryUid as DeliveryType)) {
      return
    }

    if (!request.lastMileHub.length) {
      return
    }

    return loadAvailableTimeSlots()
  })

  onTimeSlotsLoadError.subscribe(() => {
    timeSlotsLoadFailed.value = true
  })

  return {
    expressPickupDays,
    timeSlotsLoading,
    timeSlotsLoadFailed
  }
}
