










































































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Banner as BannerType } from '~/src/Model/Banner/Banner'
import Banner from '~/components/Banner/Banner.vue'
import { useInteralLinkFactory } from '~/src/Infrastructure/InternalLinkFactory/InternalLinkFactory'
import SmallBannerItemDescription from '~/components/Homepage/Components/SmallBannerItemDescription.vue'
import SmallBannerItemWrapper from '~/components/Homepage/Components/SmallBannerItemWrapper.vue'
import TextSkeleton from '~/components/DesignSystem/Skeleton/TextSkeleton.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import PageSection from '~/components/DesignSystem/PageSection/PageSection.vue'

export default defineComponent({
  components: {
    SmallBannerItemDescription,
    SmallBannerItemWrapper,
    TextSkeleton,
    Banner,
    Row,
    Button,
    PageSection
  },
  props: {
    smallBanners: {
      type: Array as PropType<BannerType[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const { isInternalLink, getRelativePath } = useInteralLinkFactory()
    const bannerAdditionalClass = 'img-box--fixed-ratio d-block maw-300px bs-mx-auto'
    const bannerSizeMobile = 'bnr-w210h210'
    const bannerMediaMobile = '(max-width: 419px), (min-width: 768px) and (max-width: 999px)'
    const bannerSizeDesktop = 'bnr-w295h295'
    const bannerTag = 'span'
    const bannerDescriptionClass = 'square-action__text'

    return {
      isInternalLink,
      getRelativePath,
      bannerAdditionalClass,
      bannerSizeMobile,
      bannerMediaMobile,
      bannerSizeDesktop,
      bannerTag,
      bannerDescriptionClass
    }
  }
})
