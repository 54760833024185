



































































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { ProductAvailabilityItem } from '~/src/Model/Product/ProductAvailabilityModal'
import ProductAvailabilityListItem
  from '~/components/ProductAvailability/ProductAvailabilityModal/ProductAvailabilityListItem.vue'
import SpinnerMessage from '~/components/Loading/SpinnerMessage.vue'
import { useExpressPickupProductTimeSlots } from '~/src/Infrastructure/ExpressPickup/ExpressPickupProductTimeSlots'

export default defineComponent({
  components: { SpinnerMessage, ProductAvailabilityListItem },
  props: {
    productId: {
      type: Number,
      required: true
    },
    courier: {
      type: Object as PropType<ProductAvailabilityItem>,
      required: true
    },
    zip: {
      type: String,
      required: true
    },
    afterDeadline: {
      type: Boolean,
      default: false
    },
    isActiveChristmas: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { timeSlotsLoading, expressPickupDays, timeSlotsLoadFailed } = useExpressPickupProductTimeSlots(props.productId, {
      postcode: props.zip,
      deliveryUid: props.courier.uid ?? '',
      lastMileHub: props.courier.deliveryDateInfo.urbantzLastMileHubId ?? '',
      startingHub: props.courier.deliveryDateInfo.expeditionWarehouseId?.toString() ?? ''
    })

    return {
      timeSlotsLoading,
      expressPickupDays,
      timeSlotsLoadFailed
    }
  }
})
