import { ref, useRouter, useFetch } from '@nuxtjs/composition-api'
import { Purchase } from '~/src/Model/Purchase/Purchase'
import {
  useCustomerPurchasesRepository,
  Parameters,
  FilterParameters
} from '~/src/Infrastructure/CustomerPurchases/CustomerPurchasesRepository'
import { usePromise } from '~/src/Infrastructure/Api/Promise'

export const useCustomerPurchasesFetch = (customerId: number, parameters: Parameters) => {
  const { loadPurchases } = useCustomerPurchasesRepository()
  let activeParameters: Parameters = { ...parameters }
  const page = ref<number>(parameters.page)
  const purchases = ref<Purchase[]>([])
  const totalCount = ref<number>(0)
  const clearFetch = ref<boolean>(true)
  const router = useRouter()

  const { exec: fetchPurchases, loading } = usePromise(async () => {
    const response = await loadPurchases(customerId, activeParameters)

    if (clearFetch.value) {
      purchases.value = response.purchases
    } else {
      purchases.value.push(...response.purchases)
    }

    totalCount.value = response.totalCount
  })

  useFetch(fetchPurchases)

  const changePage = (pageNumber: number) => {
    page.value = pageNumber
    activeParameters.page = pageNumber
    clearFetch.value = true
    fetchPurchases()
  }

  const changeFilter = (filterParameters: FilterParameters, pageNumber: number) => {
    page.value = pageNumber
    activeParameters = {
      ...filterParameters,
      page: pageNumber,
      limit: activeParameters.limit,
      onlyActive: activeParameters.onlyActive,
      excludeActive: activeParameters.excludeActive
    }
    clearFetch.value = true
    fetchPurchases()
  }

  const loadMore = () => {
    page.value++
    activeParameters.page = page.value
    clearFetch.value = false
    fetchPurchases()
  }

  const resetPageQuery = () => {
    if (page.value === 1) {
      return
    }

    const updatedQuery = { ...router.currentRoute.query }
    delete updatedQuery.page
    router.push({ path: router.currentRoute.path, query: updatedQuery })
  }

  const forceReload = () => {
    resetPageQuery()
    clearFetch.value = true
    fetchPurchases()
  }

  return {
    purchases,
    page,
    changePage,
    changeFilter,
    loadMore,
    totalCount,
    forceReload,
    fetchPurchases,
    loading
  }
}
