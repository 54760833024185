





































































































































import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'
import { isAfter } from 'date-fns'
import { GPSLocation } from '~/src/Model/PersonalPickupPoint/PersonalPickupPointRequest'
import { useProductAvailabilityModal } from '~/src/Infrastructure/ProductDetail/ProductAvailabilityModal'
import LoadingAnim from '~/components/Loading/LoadingAnim.vue'
import ProductAvailabilityList
  from '~/components/ProductAvailability/ProductAvailabilityModal/ProductAvailabilityList.vue'
import ProductAvailabilityListItem
  from '~/components/ProductAvailability/ProductAvailabilityModal/ProductAvailabilityListItem.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import TextSkeleton from '~/components/DesignSystem/Skeleton/TextSkeleton.vue'
import {
  ProductAvailabilityModal,
  DeliveryDateInfo,
  ProductAvailabilityItem
} from '~/src/Model/Product/ProductAvailabilityModal'
import { useDateFormatter } from '~/src/Infrastructure/Date/DateFormatter'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import {
  useChristmasDeliveryHelpers
} from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryHelpers'
import ProductAvailabilityCourier
  from '~/components/ProductAvailability/ProductAvailabilityModal/ProductAvailabilityCourier.vue'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  components: { ProductAvailabilityCourier, TextSkeleton, ProductAvailabilityListItem, ProductAvailabilityList, LoadingAnim, Alert },
  props: {
    productId: {
      type: Number,
      required: true
    },
    orderAllowed: {
      type: Boolean,
      required: true
    },
    reservationOnly: {
      type: Boolean,
      required: true
    },
    zip: {
      type: String,
      required: true
    },
    coordinates: {
      type: Object as PropType<GPSLocation>,
      required: true
    }
  },
  emits: ['add-to-basket'],
  setup (props) {
    const formatter = useDateFormatter()
    const { i18n } = useTranslator()
    const { karpatyStopSelling } = useWebConfig()

    const {
      loading,
      loadAvailability,
      availability,
      pickupPlacesSomeAfterDeadline,
      pickupPlacesAllAfterDeadline,
      pickupPlacesSamePrice,
      pickupPlacesLowestPrice,
      pickupPlacesSameDeliveryDate,
      pickupPlacesNamesString,
      pickupPlacesSameDeliveryDateString,
      isActiveChristmas,
      christmasDeliveryConfiguration,
      pickupPlacesSameDeliveryDateMessage
    } = useProductAvailabilityModal()

    const { getUidFromMarketingType } = useChristmasDeliveryHelpers()

    const personalPickupPointsReducedVisibleCount = 5

    const showAllPersonalPickupPoints = ref<boolean>(false)

    const firstPickupPointWithWorseAvailabilityIndex = ref<ProductAvailabilityItem | null>(null)

    const personalPickupPoints = computed<ProductAvailabilityItem[]>(() => {
      const pickupPoints = availability.value?.personalPickupPoints.pickupPoints
      if (!pickupPoints) {
        return []
      }

      pickupPoints.sort((a, b) => {
        if (a.deliveryDateInfo.index === null || b.deliveryDateInfo.index === null) {
          return 0
        }

        if (a.deliveryDateInfo.index > 1 && b.deliveryDateInfo.index > 1) {
          return 0
        }

        return a.deliveryDateInfo.index - b.deliveryDateInfo.index
      })

      if (pickupPoints.some(pickupPoint => pickupPoint.deliveryDateInfo.index === 1)) {
        firstPickupPointWithWorseAvailabilityIndex.value = pickupPoints.find(pickupPoint => pickupPoint.deliveryDateInfo.index !== 1) || null
      } else {
        firstPickupPointWithWorseAvailabilityIndex.value = null
      }

      if (showAllPersonalPickupPoints.value) {
        return pickupPoints
      }

      return pickupPoints.slice(0, personalPickupPointsReducedVisibleCount)
    })

    const afterDeadline = (uid: string | null) => {
      if (!uid) {
        return false
      }
      const deadline = christmasDeliveryConfiguration.value?.find(config => config.uid === uid)?.deadline
      return deadline ? isAfter(Date.now(), new Date(deadline)) : false
    }

    const pickupPointsDeadline = computed<string | undefined>(() => christmasDeliveryConfiguration.value.find(config => config.uid === 'DEL_PERSONAL_PRODEJNA')?.deadline)

    const pickupPointMessage = (deliveryDateInfo: DeliveryDateInfo) => {
      if (deliveryDateInfo.index === 1) {
        return i18n.t('product_detail.availability_new.within_15_minutes') as string
      }

      if (deliveryDateInfo.index === 2 && !!deliveryDateInfo.date) {
        return formatter.userDayDate(deliveryDateInfo.date, 'yyyy-MM-dd')
      }

      return deliveryDateInfo.message
    }

    const pickupPointAfterDeadline = (deliveryDateInfo: DeliveryDateInfo) => {
      if (!pickupPointsDeadline.value) {
        return false
      }
      if (isAfter(Date.now(), new Date(pickupPointsDeadline.value!))) {
        return true
      }

      if (deliveryDateInfo.index === 2) {
        const deadlineDate = formatter.dateInputFormat(pickupPointsDeadline.value!, 'yyyy-MM-dd H:mm:ss')
        return !!deliveryDateInfo.date && isAfter(new Date(deliveryDateInfo.date), new Date(deadlineDate))
      }

      return deliveryDateInfo.index !== 1
    }

    const showWrappedList = computed<boolean>(() => {
      if (pickupPlacesAllAfterDeadline.value) {
        return true
      }
      return !pickupPlacesSomeAfterDeadline.value && pickupPlacesSameDeliveryDate.value && (availability.value as ProductAvailabilityModal).pickupPlaces.pickupPlaces.length > 1
    })

    watch(props, () => {
      loadAvailability(props.productId, {
        coordinates: props.coordinates,
        postcode: props.zip.trim().replace(/\s+/g, '')
      })
    }, { immediate: true })

    return {
      loading,
      personalPickupPoints,
      showAllPersonalPickupPoints,
      availability,
      isActiveChristmas,
      getUidFromMarketingType,
      afterDeadline,
      pickupPointMessage,
      pickupPointsDeadline,
      pickupPointAfterDeadline,
      showWrappedList,
      pickupPlacesSomeAfterDeadline,
      pickupPlacesAllAfterDeadline,
      pickupPlacesLowestPrice,
      pickupPlacesSamePrice,
      pickupPlacesSameDeliveryDate,
      pickupPlacesNamesString,
      pickupPlacesSameDeliveryDateString,
      pickupPlacesSameDeliveryDateMessage,
      firstPickupPointWithWorseAvailabilityIndex,
      karpatyStopSelling
    }
  }
})
