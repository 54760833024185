




























import { defineAsyncComponent, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import ProductNameLink from '~/components/Basket/Deprecated/ProductNameLink.vue'
import { AfterSaleParentPurchase } from '~/src/Model/Order/Order'
import { Type } from '~/src/Model/Purchase/Purchase'

export default defineComponent({
  components: {
    ReceiptDetailModal: defineAsyncComponent(() => import('~/components/Profile/Purchases/ReceiptDetailModal.vue')),
    PurchaseDetailModal: defineAsyncComponent(() => import('~/components/Profile/Purchases/PurchaseDetailModal.vue')),
    ProductNameLink
  },
  props: {
    alias: {
      required: true,
      type: String
    },
    quantity: {
      type: Number,
      default: null
    },
    name: {
      required: true,
      type: String
    },
    afterSaleParentPurchase: {
      required: true,
      type: Object as PropType<AfterSaleParentPurchase>
    }
  },
  setup (props) {
    const showPurchaseModal = ref<boolean>(false)
    const showReceiptModal = ref<boolean>(false)

    const openPurchaseModal = () => {
      if (props.afterSaleParentPurchase.type === Type.Receipt) {
        showReceiptModal.value = true
        return
      }
      showPurchaseModal.value = true
    }

    return {
      showPurchaseModal,
      showReceiptModal,
      openPurchaseModal
    }
  }
})
