












import { PropType } from '@nuxtjs/composition-api'
import PurchaseDetailProductBundle from '~/components/Profile/Purchases/PurchaseDetailProductBundle/PurchaseDetailProductBundle.vue'
import { PurchaseBundleInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import { OrderProductBundle } from '~/src/Model/Order/Order'

export default {
  name: 'PurchaseDetailProductBundles',
  components: { PurchaseDetailProductBundle },
  props: {
    productBundles: {
      type: Array as PropType<OrderProductBundle[]>,
      required: true
    },
    purchaseBundleInfo: {
      type: Object as PropType<PurchaseBundleInfo>,
      required: true
    }
  }
}
