



















































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import Big from 'big.js'
import { ProductBox, ProductBoxes } from '~/src/Model/ProductBox/ProductBox'
import SideBasketItemContent from '~/components/Basket/SideBasket/SideBasketItemContent.vue'
import { useProductPriceBundlesFetch } from '~/src/Infrastructure/ProductPriceBundle/ProductPriceBundlesFetch'
import Picture from '~/components/Picture/Picture.vue'
import Source from '~/components/Source/Source.vue'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import InputNumber from '~/components/DesignSystem/Input/InputNumber.vue'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  components: {
    SideBasketItemContent,
    Picture,
    Source,
    InputNumber
  },
  props: {
    productId: {
      required: true,
      type: Number
    },
    disabledQuantity: {
      type: Boolean,
      default: false
    },
    quantityContainerClass: {
      type: String,
      default: ''
    },
    maxQuantity: {
      type: Number,
      default: null
    },
    disableQuantityTooltip: {
      type: Boolean,
      default: false
    },
    quantity: {
      type: Number,
      default: 1
    },
    productBoxes: {
      type: Object as PropType<ProductBoxes | null>,
      default: null
    },
    isReservation: {
      type: Boolean,
      default: false
    },
    isGiftVoucher: {
      type: Boolean,
      default: false
    },
    payMethodPrice: {
      type: String,
      default: '0'
    }
  },
  emits: ['update:quantity', 'price-updated', 'refund-updated'],
  setup (props, { emit }) {
    const { productPriceBundle } = useProductPriceBundlesFetch(props.productId)
    const { isZAMorVIP, isB2B } = useAuthenticatedUser()
    const { loyaltyClubAllowed } = useWebConfig()

    const product = computed<ProductBox | null>(() => props.productBoxes?.productBoxes?.[0] ?? null)
    const showLoyaltyCardRefund = computed<boolean>(() => loyaltyClubAllowed && !props.isGiftVoucher && !isZAMorVIP.value && !isB2B.value)

    const totalPrice = computed<string>(() => {
      if (productPriceBundle.value === null) {
        return '0'
      }
      const price = new Big(productPriceBundle.value.sellPrice.amount).mul(props.quantity).plus(props.payMethodPrice).toString()
      emit('price-updated', price)

      return price
    })

    const loyaltyCardRefund = computed<string>(() => {
      if (productPriceBundle.value === null) {
        return '0'
      }
      const refund = new Big(productPriceBundle.value.loyaltyCardRefund.value).mul(props.quantity).toString()
      emit('refund-updated', refund)

      return refund
    })

    const changeQuantity = (value: number) => {
      emit('update:quantity', value)
    }

    return {
      changeQuantity,
      loyaltyCardRefund,
      totalPrice,
      product,
      showLoyaltyCardRefund
    }
  }
})
