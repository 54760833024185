import { ref, useFetch } from '@nuxtjs/composition-api'
import { Page } from '~/src/Model/Page/Page'
import { usePagesRepository } from '~/src/Infrastructure/Page/PagesRepository'
import { usePromise } from '~/src/Infrastructure/Api/Promise'

export const usePageFetch = (id: number) => {
  const page = ref<Page | null>(null)
  const loaded = ref<boolean>(false)
  const { loadPage } = usePagesRepository()

  const { exec, loading, onSuccess } = usePromise(async () => {
    page.value = await loadPage(id)
    loaded.value = true
  })

  useFetch(exec)

  return {
    page,
    loading,
    onSuccess,
    loaded
  }
}
