import { PageThumbnail } from '~/src/Model/Page/PageThumbnail'

export interface TopBrands {
  topBrands: PageThumbnail[]
  totalCount: number
}

export enum SortBy {
  random = 'random',
  name = 'name'
}

export interface TopBrandsRequest {
  limit: number
  page: number
  sortBy: SortBy
}
