








































































import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import { Web } from '~/src/Model/Config/Web'
import { DigitalProductOrderCustomerInfo } from '~/src/Model/DigitalProductOrder/DigitalProductOrder'
import SectionTitle from '~/components/Order/SectionTitle/SectionTitle.vue'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import PhoneInput from '~/components/DesignSystem/Input/PhoneInput.vue'

export default defineComponent({
  components: {
    SectionTitle,
    Row,
    Column,
    FormInput,
    PhoneInput
  },
  props: {
    customerInfo: {
      required: true,
      type: Object as PropType<DigitalProductOrderCustomerInfo>
    }
  },
  emits: ['update:customer-info'],
  setup (props, { emit }) {
    const $webConfig = useWebConfig()
    const localCustomerInfo = ref<DigitalProductOrderCustomerInfo>(cloneDeep(props.customerInfo))

    const isNay = computed<boolean>(() => {
      return $webConfig.web === Web.Nay
    })

    watch(() => props.customerInfo, () => {
      localCustomerInfo.value = cloneDeep(props.customerInfo)
    })

    const updateBaseInfo = () => {
      emit('update:customer-info', cloneDeep(localCustomerInfo.value))
    }

    return {
      updateBaseInfo,
      isNay,
      localCustomerInfo
    }
  }
})
