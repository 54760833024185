






import { defineComponent } from '@nuxtjs/composition-api'

interface PropTypes {
  tag: string | null
}

export default defineComponent<PropTypes>({
  props: {
    tag: {
      type: String,
      default: 'ol'
    },
    size: {
      type: String,
      default: null
    }
  },

  setup () {
  }
})
