<template>
  <div>
    <BannerCarousel
      :banners-loading="homePageLoading"
      :banners="homePage && homePage.topBanners ? homePage.topBanners : []"
      :show-nav="true"
      carousel-class="carousel--homepage"
      size-mobile="bnr-w575h430"
      size-desktop="bnr-w1280h360"
    />

    <Container lg>
      <client-only>
        <LastPurchase v-if="authenticatedUser" :customer-id="authenticatedUser.id" />
      </client-only>

      <LoyaltyProducts
        v-if="showLoyaltyProducts"
        :loyalty-products="loyaltyProducts"
        :loading="loyaltyProductsLoading"
      />

      <HomepagePages :promoted-pages="homePage && homePage.promotedPages ? homePage.promotedPages : []" :interesting-pages="homePage && homePage.interestingPages ? homePage.interestingPages : []" class="bs-mb-3" />

      <template v-if="homePage">
        <SmallBanners :small-banners="homePage.smallBanners" />
      </template>
      <template v-else>
        <SmallBanners
          :small-banners="createEmptyBanners(4)"
          loading
        />
      </template>

      <TopBrands />

      <LazyRecommendedProducts :page-id="HOMEPAGE_ID" />

      <MagazineLatestArticles />

      <client-only>
        <VisitedProducts />
      </client-only>
    </Container>
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import LastPurchase from '~/components/Homepage/LastPurchase/LastPurchase'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import VisitedProducts from '~/components/VisitedProducts/VisitedProducts'
import MagazineLatestArticles from '~/components/MagazineLatestArticles/MagazineLatestArticles'
import TopBrands from '~/components/TopBrands/TopBrands'
import { useHomePage } from '~/src/Infrastructure/HomePage/HomePage'
import BannerCarousel from '~/components/Banner/BannerCarousel'
import HomepagePages from '~/components/Homepage/HomepagePages.vue'
import SmallBanners from '~/components/Homepage/SmallBanners.vue'
import { useSeoMetaTags } from '~/src/Infrastructure/Meta/useSeoMetaTags'
import { HOMEPAGE_ID } from '~/src/Model/HomePage/HomePage'
import LazyRecommendedProducts from '~/components/Homepage/RecommendedProducts/LazyRecommendedProducts'
import { createEmptyBanners } from '~/src/Model/Banner/Banner'
import LoyaltyProducts from '~/components/LoyaltyProducts/LoyaltyProducts.vue'
import { useDataLayerStore } from '~/src/Infrastructure/DataLayer/DataLayerStore'
import { useDataLayerOnPageLoadedExecution } from '~/src/Infrastructure/DataLayer/DataLayerOnPageLoadedExecution'
import { useLUX } from '~/src/Infrastructure/LUX/LUX'

export default defineComponent({
  components: {
    Container,
    LazyRecommendedProducts,
    LoyaltyProducts,
    TopBrands,
    MagazineLatestArticles,
    VisitedProducts,
    LastPurchase,
    BannerCarousel,
    HomepagePages,
    SmallBanners
  },
  setup () {
    const { authenticatedUser } = useAuthenticatedUser()
    const {
      homePage,
      homePageLoading,
      homePageLoaded,
      onHomePageLoaded,
      loyaltyProductsLoading,
      loyaltyProducts,
      recommendedProducts,
      recommendedProductsLoading,
      showLoyaltyProducts
    } = useHomePage()

    const lux = useLUX()
    useSeoMetaTags(homePage)
    const dataLayerStore = useDataLayerStore()

    useDataLayerOnPageLoadedExecution(() => {
      dataLayerStore.fireVirtualPageViewEvent()
    }, homePageLoaded)

    onHomePageLoaded.subscribe(() => {
      lux.pageLoaded()
    })

    return {
      authenticatedUser,
      homePage,
      homePageLoading,
      HOMEPAGE_ID,
      createEmptyBanners,
      recommendedProducts,
      recommendedProductsLoading,
      loyaltyProducts,
      loyaltyProductsLoading,
      showLoyaltyProducts
    }
  },
  head: { }

})
</script>
