



























































































import { defineComponent, PropType, ref, useRoute } from '@nuxtjs/composition-api'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import { Banner as BannerType } from '~/src/Model/Banner/Banner'
import Banner from '~/components/Banner/Banner.vue'
import { useInteralLinkFactory } from '~/src/Infrastructure/InternalLinkFactory/InternalLinkFactory'
import LoadingAnim from '~/components/Loading/LoadingAnim.vue'

export default defineComponent({
  components: { Container, LoadingAnim, Banner },
  props: {
    banners: {
      type: Array as PropType<BannerType[]>,
      required: true
    },
    bannersLoading: {
      required: true,
      type: Boolean
    },
    carouselClass: {
      type: String,
      default: null
    },
    showNav: {
      type: Boolean,
      default: false
    },
    sizeMobile: {
      type: String,
      default: null
    },
    sizeDesktop: {
      type: String,
      default: null
    },
    breakpoint: {
      type: Number,
      default: undefined
    }
  },
  setup () {
    const route = useRoute()
    const { isInternalLink, getRelativePath } = useInteralLinkFactory()

    const currentIndex = ref<Number>(0)

    const changeCurrentIndex = (newIndex: number) => {
      currentIndex.value = newIndex
    }

    const bannerImageLoading = (index: number) => {
      return index > 0 ? 'lazy' : 'eager'
    }

    const shouldPreloadBanner = (index: number) => {
      return index === 0
    }

    const shouldUseClientNavigation = (url: string): boolean => {
      if (isInternalLink(url)) {
        const relativePath = getRelativePath(url)
        const bannerUrlPath = relativePath.split('/')[1]
        const currentUrlPath = route.value.path.split('/')[1]
        return bannerUrlPath !== currentUrlPath
      }
      return false
    }

    return {
      currentIndex,
      changeCurrentIndex,
      isInternalLink,
      getRelativePath,
      bannerImageLoading,
      shouldPreloadBanner,
      shouldUseClientNavigation
    }
  }
})
