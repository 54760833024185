













import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    publishedDate: {
      type: String,
      required: true
    },
    readTime: {
      type: Number,
      default: null
    }
  }
})
