import { LoyaltyProducts } from '~/src/Model/LoyaltyProducts/LoyaltyProducts'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useLoyaltyProductsRepository = () => {
  const { $axios } = useAxios()

  return {
    loadLoyaltyProducts: (limit: number, page: number = 1): Promise<LoyaltyProducts> => $axios.$get<LoyaltyProducts>('/loyalty-products', {
      params: {
        limit,
        page
      }
    })
  }
}
