

























import { defineComponent } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import TextSkeleton from '~/components/DesignSystem/Skeleton/TextSkeleton.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'

export default defineComponent({
  components: { ImageWithFallback, TextSkeleton, Column },
  props: {
    page: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup () {}
})
