import { OrderBillingAddress, OrderCompanyInfo } from '~/src/Model/Order/CustomerInfo/OrderCustomerInfo'

export interface DigitalProductOrderCustomerInfo {
  firstName: string
  lastName: string
  email: string
  phone: string
  billingAddress: OrderBillingAddress | null
  companyInfo: OrderCompanyInfo | null
}

export interface DigitalProductOrderRequest {
  productId: number
  payMethodId: number
  agreedWithConditions: boolean
  quantity: number
  customerInfo: DigitalProductOrderCustomerInfo
}

export const emptyDigitalProductCustomerFormInfo = (): DigitalProductOrderCustomerInfo => {
  return {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    companyInfo: {
      name: '',
      ico: '',
      dic: null,
      icDph: null
    },
    billingAddress: {
      street: '',
      city: '',
      zip: '',
      addressNote: null,
      countryCode: null
    }
  }
}
