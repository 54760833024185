






import { defineComponent, ref } from '@nuxtjs/composition-api'
import { useVisibilityLazyLoad } from '~/src/Infrastructure/VisbilityLazyLoad/VisibilityLazyLoad'
import RecommendedProducts from '~/components/Homepage/RecommendedProducts/RecommendedProducts.vue'
import { useRecommendedProductsFetch } from '~/src/Infrastructure/RecommendedProducts/RecommendedProductsFetch'

const LAZY = true

export default defineComponent({
  components: {
    RecommendedProducts
  },
  props: {
    pageId: {
      required: true,
      type: Number
    },
    limit: {
      type: Number,
      default: 6
    }
  },
  setup (props) {
    const observingItem = ref<HTMLElement | null>(null)
    const { loadRecommendedProducts, loading, recommendedProducts } = useRecommendedProductsFetch(props.pageId, props.limit, LAZY)

    useVisibilityLazyLoad(
      observingItem,
      loadRecommendedProducts,
      {
        rootMargin: '400px'
      }
    )

    return {
      recommendedProducts,
      observingItem,
      loading
    }
  }
})
