import { ref, useFetch, watch } from '@nuxtjs/composition-api'
import { useProductBoxesRepository } from '~/src/Infrastructure/ProductBoxes/ProductBoxesRepository'
import { ProductBoxes } from '~/src/Model/ProductBox/ProductBox'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'

export const useProductBoxesFetch = (ids: number[]) => {
  const productBoxes = ref<ProductBoxes | null>(null)
  const { loadProductBoxes } = useProductBoxesRepository()
  const { isUserAuthenticated, isNormal } = useAuthenticatedUser()

  const fetchProductBoxes = async () => {
    productBoxes.value = await loadProductBoxes(ids)
  }

  useFetch(fetchProductBoxes)

  watch(isUserAuthenticated, () => {
    if (isNormal.value) {
      return
    }
    fetchProductBoxes()
  })

  return {
    productBoxes
  }
}
