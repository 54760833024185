




























import { defineComponent, ref } from '@nuxtjs/composition-api'
import PageModal from '~/components/Page/PageModal.vue'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import SectionTitle from '~/components/Order/SectionTitle/SectionTitle.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: {
    SectionTitle,
    PageModal,
    Alert
  },
  props: {
    showNoReturnMessage: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const showConditionsModal = ref<boolean>(false)
    const systemPagesStore = useSystemPagesStore()
    const conditionsPath = systemPagesStore.pathByUid('condition')

    return {
      showConditionsModal,
      conditionsPath
    }
  }
})
