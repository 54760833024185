

















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { OrderTimeSlot } from '~/src/Model/Order/Order'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'p'
    },
    messageTag: {
      type: String,
      default: 'strong'
    },
    marginClass: {
      type: String,
      default: 'bs-mb-0 bs-mt-1'
    },
    timeSlot: {
      default: null,
      type: Object as PropType<OrderTimeSlot | null>
    }
  }
})
