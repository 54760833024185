







import { defineComponent, PropType } from '@nuxtjs/composition-api'
import PurchaseDetailBundleProduct from '~/components/Profile/Purchases/PurchaseDetailProduct/PurchaseDetailBundleProduct.vue'
import { OrderProductBundleItem } from '~/src/Model/Order/Order'
import { PurchaseBundleInfo, PurchaseInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'

export default defineComponent({
  components: { PurchaseDetailBundleProduct },
  props: {
    productBundleItem: {
      type: Object as PropType<OrderProductBundleItem>,
      required: true
    },
    purchaseBundleInfo: {
      type: Object as PropType<PurchaseBundleInfo>,
      required: true
    }
  },

  setup (props) {
    const scaffoldPurchaseInfo = (productId: number): PurchaseInfo => {
      return {
        ...props.purchaseBundleInfo,
        itemId: productId
      }
    }

    return {
      scaffoldPurchaseInfo
    }
  }
})
