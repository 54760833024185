























import { defineComponent } from '@nuxtjs/composition-api'
import ReceiptDetail from '~/components/Receipt/Detail/ReceiptDetail.vue'
import CopyButton from '~/components/DesignSystem/Buttons/CopyButton.vue'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'

interface PropTypes {
  receiptNumber: Number
}

export default defineComponent<PropTypes>({
  components: {
    ReceiptDetail,
    CopyButton,
    Modal
  },
  props: {
    receiptNumber: {
      type: Number,
      required: true
    }
  },
  emits: ['close-modal']
})
