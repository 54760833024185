












import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {},
  props: {
    alias: {
      required: true,
      type: String
    },
    quantity: {
      type: Number,
      default: null
    },
    name: {
      required: true,
      type: String
    },
    type: {
      type: String,
      default: null
    }
  }
})
