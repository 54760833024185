















import { defineComponent } from '@nuxtjs/composition-api'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'

export default defineComponent({
  components: {
    Modal
  },
  emits: ['close-modal'],
  setup () {}
})
