






import { defineComponent } from '@nuxtjs/composition-api'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'span'
    }
  },
  setup () {
    const { isNay } = useWebConfig()

    return { isNay }
  }
})
