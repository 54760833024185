import {
  DigitalProductOrderCustomerInfo,
  DigitalProductOrderRequest
} from '~/src/Model/DigitalProductOrder/DigitalProductOrder'

export class DigitalProductOrderRequestFactory {
  public create (
    productId: number, quantity: number, agreedWithConditions: boolean, payMethodId: number, customerInfo: DigitalProductOrderCustomerInfo): DigitalProductOrderRequest {
    return {
      productId,
      agreedWithConditions,
      payMethodId,
      quantity,
      customerInfo
    }
  }
}

export const useDigitalProductOrderRequestFactory = (): DigitalProductOrderRequestFactory => new DigitalProductOrderRequestFactory()
