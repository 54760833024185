










































import { defineComponent, ref } from '@nuxtjs/composition-api'
import { useVisibilityLazyLoad } from '~/src/Infrastructure/VisbilityLazyLoad/VisibilityLazyLoad'
import { SortBy, TopBrands } from '~/src/Model/TopBrands/TopBrands'
import { useTopBrandsRepository } from '~/src/Infrastructure/TopBrands/TopBrandsRepository'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import PageSection from '~/components/DesignSystem/PageSection/PageSection.vue'

export default defineComponent({
  components: {
    ImageWithFallback,
    Row,
    Column,
    Button,
    PageSection
  },
  setup () {
    const topBrands = ref<TopBrands | null>(null)
    const { loadTopBrands } = useTopBrandsRepository()

    const observingItem = ref<HTMLElement | null>(null)

    const imgBoxClass = 'brand-logos__img-box img-box img-box--fixed-ratio d-block bs-mx-auto'

    const loadData = async () => {
      topBrands.value = await loadTopBrands({
        limit: 6,
        page: 1,
        sortBy: SortBy.random
      })
    }

    useVisibilityLazyLoad(
      observingItem,
      loadData,
      {
        rootMargin: '400px'
      }
    )

    return {
      topBrands,
      observingItem,
      imgBoxClass
    }
  }
})
