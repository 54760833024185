import { TopBrands, TopBrandsRequest } from '~/src/Model/TopBrands/TopBrands'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useTopBrandsRepository = () => {
  const { $axios } = useAxios()

  return {
    loadTopBrands: (request: TopBrandsRequest): Promise<TopBrands> => $axios.$get<TopBrands>('/top-brands', {
      params: {
        limit: request.limit,
        page: request.page,
        sortBy: request.sortBy
      }
    })
  }
}
