










import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'li'
    },
    icon: {
      type: String,
      required: true
    },
    last: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    done: {
      type: Boolean,
      default: false
    }
  },

  setup () {
  }
})
