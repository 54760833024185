








































































import { defineComponent, ref, onUpdated, watch, onMounted } from '@nuxtjs/composition-api'
import debounce from 'lodash/debounce'
import { useResizeObserver } from '~/src/Infrastructure/WebAPI/ResizeObserver'
import { useRequiredTemplateRef } from '~/src/Infrastructure/Vue/TemplateRef'
import PageSection from '~/components/DesignSystem/PageSection/PageSection.vue'

const enum ScrollDirection {
  RIGHT = 'right',
  LEFT = 'left'
}

export default defineComponent({
  components: { PageSection },
  props: {
    tag: {
      type: String,
      default: undefined
    },
    justifyContentLgCenter: {
      type: Boolean,
      default: false
    },
    isProductListMinimal: {
      type: Boolean,
      default: true
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    sliderClass: {
      type: String,
      default: null
    },
    contentClass: {
      type: String,
      default: null
    },
    hideScrollbar: {
      type: Boolean,
      default: false
    },
    controlsDisplaySwitch: {
      type: String,
      default: 'md'
    }
  },
  setup () {
    const PRODUCT_BOX_MARGIN = 22
    const PRODUCT_BOX_HALF_WIDTH = 80

    const productListElement = useRequiredTemplateRef<HTMLElement>()
    const isScrollable = ref<boolean>(false)

    const scrollDistance = ref<number>(0)

    const scrollLeftDisabled = ref<boolean>(true)
    const scrollRightDisabled = ref<boolean>(true)

    const scrollbarWidth = ref<number | null>(null)
    const scrollbarPosition = ref<number | null>(null)

    const resizeDebouncer = debounce(function () { setScrollable() }, 400, { leading: false, trailing: true })
    const scrollCalculationDebouncer = debounce(function () { calculateScrollData() }, 100, { leading: false, trailing: true })

    useResizeObserver(productListElement, function () {
      resizeDebouncer()
    })

    onUpdated(() => {
      calculateScrollData()
      setScrollable()
    })

    onMounted(() => {
      productListElement.value.addEventListener('scroll', () => scrollCalculationDebouncer())
    })

    const scrollLeft = () => {
      calculateScrollStepCorrection(ScrollDirection.LEFT)
      productListElement.value.scrollBy(Number(scrollDistance.value * -1), 0)
    }

    const scrollRight = () => {
      calculateScrollStepCorrection(ScrollDirection.RIGHT)
      productListElement.value.scrollBy(scrollDistance.value, 0)
    }

    const setScrollable = () => {
      isScrollable.value = productListElement.value ? productListElement.value.scrollWidth > productListElement.value.offsetWidth : false
    }

    const calculateScrollData = () => {
      scrollDistance.value = productListElement.value.children[0]?.clientWidth ?? 0

      scrollbarWidth.value = productListElement.value.clientWidth / productListElement.value.scrollWidth * 100
      scrollbarPosition.value = productListElement.value.scrollLeft / productListElement.value.scrollWidth * 100

      scrollLeftDisabled.value = productListElement.value.scrollLeft <= PRODUCT_BOX_MARGIN
      scrollRightDisabled.value = productListElement.value.scrollLeft >= productListElement.value.scrollWidth - productListElement.value.clientWidth - PRODUCT_BOX_MARGIN
    }

    const calculateScrollStepCorrection = (scrollDirection: ScrollDirection) => {
      const scrollLeftMax = productListElement.value.scrollWidth - productListElement.value.clientWidth
      const distanceToScrollEnd = scrollDirection === ScrollDirection.LEFT
        ? productListElement.value.scrollLeft - scrollDistance.value
        : scrollLeftMax - (productListElement.value.scrollLeft + scrollDistance.value)

      if (distanceToScrollEnd <= PRODUCT_BOX_HALF_WIDTH) {
        scrollDistance.value = scrollDistance.value + distanceToScrollEnd
      }
    }

    watch(isScrollable, () => {
      if (isScrollable.value) {
        calculateScrollData()
      }
    })

    return {
      scrollLeft,
      scrollRight,
      scrollLeftDisabled,
      scrollRightDisabled,
      isScrollable,
      productListElement,
      scrollDistance,
      scrollbarWidth,
      scrollbarPosition
    }
  }
})
