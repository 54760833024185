import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import { UserBillingAddress, UserDeliveryAddress } from '~/src/Model/Customer/UserAddress'

export const useCustomerHelpers = () => {
  const { countryCode } = useWebConfig()

  const customerCountryCode = countryCode.toUpperCase()

  const billingAddressHasEmptyAllRequiredInfo = (address: UserBillingAddress): boolean => {
    return !address.firstName && !address.lastName && !address.street && !address.city && !address.zip
  }

  const deliveryAddressHasEmptyAllRequiredInfo = (address: UserDeliveryAddress): boolean => {
    return !address.firstName && !address.lastName && !address.street && !address.city && !address.zip && !address.phone && !address.email
  }

  const billingAddressHasAllRequiredInfo = (address: UserBillingAddress): boolean => {
    const addressInfo = [address.firstName, address.lastName, address.street, address.zip, address.city]
    if (address.companyInfo) {
      addressInfo.push(address.companyInfo.ico, address.companyInfo.name)
    }

    return addressInfo.every(value => value)
  }

  const deliveryAddressHasAllRequiredInfo = (address: UserDeliveryAddress): boolean => {
    return [address.firstName, address.lastName, address.phone, address.email, address.street, address.zip, address.city].every(value => value)
  }

  return {
    customerCountryCode,
    deliveryAddressHasEmptyAllRequiredInfo,
    deliveryAddressHasAllRequiredInfo,
    billingAddressHasEmptyAllRequiredInfo,
    billingAddressHasAllRequiredInfo
  }
}
