




import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { ProductBox } from '~/src/Model/ProductBox/ProductBox'
import MinimalProductBox from '~/components/ProductBox/MinimalProductBox.vue'

export default defineComponent({
  components: {
    MinimalProductBox
  },
  props: {
    productBox: {
      required: true,
      type: Object as PropType<ProductBox>
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
