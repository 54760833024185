import { ref, useFetch, watch } from '@nuxtjs/composition-api'
import { useProductPriceBundlesRepository } from '~/src/Infrastructure/ProductPriceBundle/ProductPriceBundlesRepository'
import { ProductPriceBundle } from '~/src/Model/ProductPriceBundle/ProductPriceBundle'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'

export const useProductPriceBundlesFetch = (id: number) => {
  const productPriceBundle = ref<ProductPriceBundle | null>(null)
  const { loadProductPriceBundle } = useProductPriceBundlesRepository()
  const { isNormal, isUserAuthenticated } = useAuthenticatedUser()

  const fetchProductPriceBundle = async () => {
    productPriceBundle.value = await loadProductPriceBundle(id)
  }

  useFetch(fetchProductPriceBundle)

  watch(isUserAuthenticated, () => {
    if (isNormal.value) {
      return
    }
    fetchProductPriceBundle()
  })

  return {
    productPriceBundle
  }
}
