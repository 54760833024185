




















import { defineComponent } from '@nuxtjs/composition-api'
import SectionTitle from '~/components/Order/SectionTitle/SectionTitle.vue'
import SectionContent from '~/components/Order/SectionContent/SectionContent.vue'

export default defineComponent({
  components: { SectionContent, SectionTitle }
})
