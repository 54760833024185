













































































import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import { google } from 'google-maps'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'
import LoadingAnim from '~/components/Loading/LoadingAnim.vue'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import PickupPointSearch from '~/components/PickupPoint/PickupPointSearch.vue'
import { useGoogleLoader } from '~/src/Infrastructure/Nuxt/GoogleLoader/UseGoogleLoader'
import { useCustomerInfoFetch } from '~/src/Infrastructure/Customers/CustomerInfoFetch'
import { CustomerPreferredZipCodeCookieStorage } from '~/src/Infrastructure/Cookies/CustomerPreferredZipCode'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import ProductAvailabilityContent
  from '~/components/ProductAvailability/ProductAvailabilityModal/ProductAvailabilityContent.vue'
import { GPSLocation } from '~/src/Model/PersonalPickupPoint/PersonalPickupPointRequest'
import { FocusChangedConfig } from '~/src/Model/Map/MapWithPickupPoints'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import PurchaseAvailabilityButton from '~/components/ProductDetail/PurchaseAvailability/PurchaseAvailabilityButton.vue'
import { CustomerInformation } from '~/src/Model/Customer/CustomerInformation'

export default defineComponent({
  components: {
    PurchaseAvailabilityButton,
    Alert,
    ProductAvailabilityContent,
    PickupPointSearch,
    LoadingAnim,
    Modal
  },
  props: {
    productId: {
      type: Number,
      required: true
    },
    orderAllowed: {
      type: Boolean,
      required: true
    },
    reservationOnly: {
      type: Boolean,
      required: true
    },
    isPreorder: {
      type: Boolean,
      default: false
    },
    purchaseAvailabilityMessage: {
      type: String,
      default: ''
    }
  },
  emits: ['close-modal', 'add-to-basket', 'reserve'],
  setup () {
    const $webConfig = useWebConfig()
    const { authenticatedUserId } = useAuthenticatedUser()
    const { onCustomerInfoLoadedEvent, loading: loadingCustomerInfo } = useCustomerInfoFetch(authenticatedUserId.value)

    const coordinates = ref<GPSLocation | null>(null)

    const { app } = useApp()
    const customerPreferredZipCodeCookieStorage = new CustomerPreferredZipCodeCookieStorage(app.$cookies)

    const { $googleLoader } = useGoogleLoader()
    const google = ref<google | null>(null)

    const customerZip = ref<string>('')
    const zipFound = ref<boolean>(false)

    const iconPath = computed<string>(() => $webConfig.isNay ? 'nay' : 'ew')

    onCustomerInfoLoadedEvent.subscribe((customerInformation: CustomerInformation) => {
      const zip = customerInformation.addresses.delivery.find(address => address.isDefault)?.zip ?? customerInformation.addresses.billing.find(address => address.isDefault)?.zip
      if (
        zip && !(
          customerPreferredZipCodeCookieStorage.customerPreferredZipCode.length &&
          customerPreferredZipCodeCookieStorage.customerPreferredZipCode !== zip
        )
      ) {
        customerZip.value = zip
        customerPreferredZipCodeCookieStorage.setCustomerPreferredZipCode(zip)
      }
    })

    onMounted(() => {
      $googleLoader.load().then((response) => {
        google.value = response
      })

      customerZip.value = customerPreferredZipCodeCookieStorage.customerPreferredZipCode
    })

    const onCoordinatesChanged = (focusChangedConfig: FocusChangedConfig) => {
      coordinates.value = {
        lat: focusChangedConfig.coordinates.lat,
        lon: focusChangedConfig.coordinates.long
      }
    }

    const onZipFound = (zip: string) => {
      zipFound.value = true
      customerPreferredZipCodeCookieStorage.setCustomerPreferredZipCode(zip)
    }

    return {
      loading: loadingCustomerInfo,
      customerZip,
      google,
      coordinates,
      onCoordinatesChanged,
      zipFound,
      onZipFound,
      customerPreferredZipCodeCookieStorage,
      iconPath
    }
  }
})
