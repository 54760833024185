


























import { defineComponent, ref } from '@nuxtjs/composition-api'
import Vue from 'vue'
import { Severity } from '@sentry/types/dist/severity'
import { useSentry } from '~/src/Infrastructure/Nuxt/Sentry/UseSentry'
import { QueryParameters, useNavigateToSystemPage } from '~/src/Infrastructure/SystemPages/NavigateToSystemPage'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import { DigitalProductOrderRequest } from '~/src/Model/DigitalProductOrder/DigitalProductOrder'
import { NewOrderResponse } from '~/src/Model/Order/NewOrderResponse'
import { useOrderStore } from '~/src/Infrastructure/Order/OrderStore'
import useApiCall from '~/src/Infrastructure/Api/ApiCall'
import { useLocalFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/LocalFlashMessageCenter'
import { useScrollTo } from '~/src/Infrastructure/Scroll/ScrollTo'
import SpecialProductCheckoutModalLayout
  from '~/components/ProductDetail/SpecialProductCheckoutModal/SpecialProductCheckoutModalLayout.vue'
import BuyDigitalProductCheckout from '~/components/ProductDetail/DigitalProduct/BuyDigitalProductCheckout.vue'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'

export default defineComponent({
  components: { FlashMessageCenter, BuyDigitalProductCheckout, SpecialProductCheckoutModalLayout },
  props: {
    productId: {
      required: true,
      type: Number
    }
  },
  emits: ['close-modal'],
  setup () {
    const { authenticatedUserId } = useAuthenticatedUser()
    const isSubmitted = ref<boolean>(false)
    const orderStore = useOrderStore()
    const { navigateToUid } = useNavigateToSystemPage()
    const authService = useAuthService()
    const { $sentry } = useSentry()
    const { flashMessages, flashMessageCenter } = useLocalFlashMessageCenter()
    const { scrollToHtmlElement } = useScrollTo()
    const flashMessageElement = ref<Vue>()

    const submit = () => {
      isSubmitted.value = true
    }

    let newOrderResponse: NewOrderResponse
    const processOrderSubmitCallback = async (request: DigitalProductOrderRequest) => {
      newOrderResponse = await orderStore.createDigitalProductOrder(request)

      const query: QueryParameters = {}
      newOrderResponse.redirect.query.forEach(qp => Object.assign(query, { [qp.name]: qp.value }))
      authService.fetchUser()
      navigateToUid(newOrderResponse.redirect.uid, { query })
    }

    const { exec: processOrderSubmit, pending: orderPending, onError: onOrderError } = useApiCall(processOrderSubmitCallback, flashMessageCenter)

    onOrderError.subscribe((error) => {
      $sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning)
        scope.setTransactionName('Failed to create digital content order')

        $sentry.captureException(error)
      })

      if (flashMessageElement.value) {
        scrollToHtmlElement(flashMessageElement.value.$el)
      }
      isSubmitted.value = false
    })

    return {
      flashMessages,
      flashMessageElement,
      authenticatedUserId,
      submit,
      isSubmitted,
      orderPending,
      processOrderSubmit
    }
  }
})
