










import { defineComponent, computed, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    srOnlyText: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      required: true,
      type: [String, Number]
    },
    buttonMargin: {
      type: String,
      default: 'bs-ml-2'
    },
    iconSize: {
      type: String,
      default: 'icon-fs-18'
    }
  },
  setup () {
    const isTooltipped = ref<boolean>(false)
    const inputRef = ref<HTMLInputElement>()
    const buttonRef = ref<Vue>()

    const iconClass = computed<string | null>(() => {
      if (isTooltipped.value) {
        return 'icon-check text-success'
      }

      return 'icon-new-window'
    })

    const copyOrderNumber = () => {
      inputRef.value?.select()
      document.execCommand('copy')

      buttonRef.value?.$el.classList.add('tooltipped')
      isTooltipped.value = true
      setTimeout(function () {
        buttonRef.value?.$el.classList.remove('tooltipped')
        isTooltipped.value = false
      }, 2000)
    }

    return {
      inputRef,
      copyOrderNumber,
      iconClass
    }
  }
})
