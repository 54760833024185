





































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import PurchaseDetail from '~/components/Profile/Purchases/PurchaseDetail.vue'
import { Type as PurchaseType } from '~/src/Model/Purchase/Purchase'
import PurchaseStorno from '~/components/Profile/Purchases/Storno/PurchaseStorno.vue'
import StornoTimeline from '~/components/Profile/Purchases/Storno/StornoTimeline.vue'
import CopyButton from '~/components/DesignSystem/Buttons/CopyButton.vue'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'

enum PurchaseDetailComponent {
  STORNO,
  STORNO_TIMELINE,
  PURCHASE_DETAIL
}

export default defineComponent({
  components: {
    PurchaseDetail,
    PurchaseStorno,
    StornoTimeline,
    CopyButton,
    Modal
  },
  props: {
    purchaseType: {
      type: String as PropType<PurchaseType>,
      required: true
    },
    purchaseNumber: {
      type: Number,
      required: true
    }
  },
  emits: ['close-modal', 'storno-done'],
  setup (_props, { emit }) {
    const copyOrderNumber = () => {
      (document.getElementById('orderNumber') as HTMLInputElement).select()
      document.execCommand('copy')
    }

    const purchaseDetailComponent = ref<PurchaseDetailComponent>(PurchaseDetailComponent.PURCHASE_DETAIL)

    const handleSuccessfulStorno = () => {
      purchaseDetailComponent.value = PurchaseDetailComponent.STORNO_TIMELINE
      emit('storno-done')
    }

    return {
      copyOrderNumber,
      purchaseDetailComponent,
      PurchaseDetailComponent,
      handleSuccessfulStorno
    }
  }
})
