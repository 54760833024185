import { useContext } from '@nuxtjs/composition-api'
import { Severity } from '@sentry/types/dist/severity'
import { SimpleEventDispatcher } from 'ste-simple-events'

export const useSentry = () => {
  const onErrorDispatcher = new SimpleEventDispatcher()
  const { $sentry } = useContext()

  const captureException = async (exec: Function, transactionName: string | null = null, severity: Severity = Severity.Error) => {
    try {
      await exec()
    } catch (error) {
      $sentry.withScope((scope) => {
        if (transactionName) {
          scope.setTransactionName(transactionName)
        }

        scope.setLevel(severity)
        $sentry.captureException(error)
        onErrorDispatcher.dispatch(error)
      })
    }
  }

  return {
    $sentry,
    onCapturedException: onErrorDispatcher.asEvent(),
    captureException
  }
}
