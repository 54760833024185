
































































































































import { computed, defineComponent, PropType, reactive, ref, watch } from '@nuxtjs/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import { DigitalProductOrderCustomerInfo } from '~/src/Model/DigitalProductOrder/DigitalProductOrder'
import SectionTitle from '~/components/Order/SectionTitle/SectionTitle.vue'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import CheckboxInput from '~/components/DesignSystem/Input/CheckboxInput.vue'
import PhoneInput from '~/components/DesignSystem/Input/PhoneInput.vue'
import IcDphInput from '~/components/IcDphInput/IcDphInput.vue'
import DicInput from '~/components/DicInput/DicInput.vue'
import CompanyAutocomplete from '~/components/Autocomplete/CompanyAutocomplete.vue'
import {
  CompanyInfoSearchableAttributes,
  CompanySearchAddress,
  CompanySearchInfo
} from '~/src/Model/CompanySearch/CompanySearch'
import CompanyNameWarning from '~/components/Forms/CompanyNameWarning.vue'
import ZipInput from '~/components/DesignSystem/Input/ZipInput.vue'

export default defineComponent({
  components: {
    DicInput,
    IcDphInput,
    ZipInput,
    CompanyNameWarning,
    CompanyAutocomplete,
    SectionTitle,
    Row,
    Column,
    FormInput,
    CheckboxInput,
    PhoneInput
  },
  props: {
    customerInfo: {
      required: true,
      type: Object as PropType<DigitalProductOrderCustomerInfo>
    },
    useCompanyData: {
      required: true,
      type: Boolean
    }
  },
  emits: ['update:customer-info', 'company-data-switched'],
  setup (props, { emit }) {
    const $webConfig = useWebConfig()
    const localCustomerInfo = ref<DigitalProductOrderCustomerInfo>(cloneDeep(props.customerInfo))
    const localUseCompanyData = computed<boolean>({
      get: () => props.useCompanyData,
      set: use => emit('company-data-switched', use)
    })
    const autocomplete = reactive<{applied: boolean, address: CompanySearchAddress | null}>({
      applied: false,
      address: null
    })

    watch(() => props.customerInfo, () => {
      localCustomerInfo.value = cloneDeep(props.customerInfo)
    })

    const updateBaseInfo = () => {
      emit('update:customer-info', cloneDeep(localCustomerInfo.value))
    }

    const updateCompanyInfo = () => {
      emit('update:customer-info', cloneDeep(localCustomerInfo.value))
      autocomplete.applied = false
    }

    const selectAutocompleteCompany = (selectedCompany: CompanySearchInfo) => {
      if (!localCustomerInfo.value.companyInfo) {
        return
      }

      localCustomerInfo.value.companyInfo.name = selectedCompany.name
      localCustomerInfo.value.companyInfo.ico = selectedCompany.ic.toString()
      localCustomerInfo.value.companyInfo.dic = selectedCompany.dic.toString()
      if ($webConfig.useIcDph) {
        localCustomerInfo.value.companyInfo.icDph = selectedCompany.icDph?.toString() ?? ''
      }

      if (localCustomerInfo.value.billingAddress) {
        localCustomerInfo.value.billingAddress.street = `${selectedCompany.address.street ?? ''} ${selectedCompany.address.number ?? ''}`.trim()
        localCustomerInfo.value.billingAddress.city = selectedCompany.address.city ?? ''
        localCustomerInfo.value.billingAddress.zip = selectedCompany.address.zip ?? ''
      }

      autocomplete.address = selectedCompany.address
      updateBaseInfo()
      autocomplete.applied = true
    }

    return {
      updateBaseInfo,
      localUseCompanyData,
      useIcDph: $webConfig.useIcDph,
      localCustomerInfo,
      selectAutocompleteCompany,
      updateCompanyInfo,
      autocomplete,
      CompanyInfoSearchableAttributes
    }
  }
})
