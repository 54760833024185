























import { defineComponent, computed, ref } from '@nuxtjs/composition-api'
import { useBasketInsertProduct } from '~/src/Infrastructure/Basket/BasketInsert'
import { useVisibilityLazyLoad } from '~/src/Infrastructure/VisbilityLazyLoad/VisibilityLazyLoad'
import { ProductBox } from '~/src/Model/ProductBox/ProductBox'
import { useVisitedProductsStore } from '~/src/Infrastructure/VisitedProducts/VisitedProductsStore'
import ProductSlider from '~/components/ProductSlider/ProductSlider.vue'
import ProductAccessorySliderItem from '~/components/ProductAccessories/ProductAccessorySliderItem.vue'

export default defineComponent({
  components: { ProductAccessorySliderItem, ProductSlider },
  props: {
    ignoredProduct: {
      type: Number,
      required: false,
      default: null
    }
  },
  setup (props) {
    const visitedProductsStore = useVisitedProductsStore()
    const observingItem = ref<HTMLElement | undefined>(undefined)
    const { basketInsertProduct, basketInsertProductPending } = useBasketInsertProduct()

    const addToBasket = async (productId: number) => {
      await basketInsertProduct(productId)
    }

    const productBoxes = computed<ProductBox[]>(() => {
      return visitedProductsStore.productBoxes.value?.filter(pb => pb.product.id !== props.ignoredProduct)
    })

    const productsListId = visitedProductsStore.productsListId
    const productsIds = visitedProductsStore.productsIds

    useVisibilityLazyLoad(
      observingItem,
      async () => await visitedProductsStore.loadProductBoxes(),
      {
        rootMargin: '400px'
      }
    )

    return {
      observingItem,
      productsListId,
      productsIds,
      productBoxes,
      visitedProductsStore,
      addToBasket,
      basketInsertProductPending
    }
  }
})
