import { DigitalProductPayMethodsResponse } from '~/src/Model/DigitalProductPayMethod/DigitalProductPayMethodsResponse'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useDigitalProductPayMethodsRepository = () => {
  const { $axios } = useAxios()

  return {
    loadPayMethods: (): Promise<DigitalProductPayMethodsResponse> => $axios.$get<DigitalProductPayMethodsResponse>('/digital-content/pay-methods')
  }
}
