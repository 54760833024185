
































import { defineComponent, ref } from '@nuxtjs/composition-api'
import { useVisibilityLazyLoad } from '~/src/Infrastructure/VisbilityLazyLoad/VisibilityLazyLoad'
import ArticleThumbnail from '~/components/Magazine/ArticleThumbnail.vue'
import { MagazineLatestArticles } from '~/src/Model/MagazineLatestArticles/MagazineLatestArticles'
import { useMagazineLatestArticlesRepository } from '~/src/Infrastructure/MagazineLatestArticles/MagazineLatestArticlesRepository'
import CurrentWeb from '~/components/Web/CurrentWeb.vue'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { createEmptyArticleThumbnail } from '~/src/Model/Magazine/Magazine'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import PageSection from '~/components/DesignSystem/PageSection/PageSection.vue'

export default defineComponent({
  components: {
    CurrentWeb,
    ArticleThumbnail,
    Row,
    Column,
    Button,
    PageSection
  },
  setup () {
    const magazineLatestArticles = ref<MagazineLatestArticles>({
      articles: [1, 2, 3, 4].map(createEmptyArticleThumbnail)
    })
    const { getArticles } = useMagazineLatestArticlesRepository()

    const observingItem = ref<HTMLElement | null>(null)

    const { exec: loadData, loading } = usePromise(async () => {
      magazineLatestArticles.value = await getArticles()
    })

    useVisibilityLazyLoad(
      observingItem,
      loadData,
      {
        rootMargin: '400px'
      }
    )

    return {
      magazineLatestArticles,
      observingItem,
      loading
    }
  }
})
