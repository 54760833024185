




















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { PageThumbnail } from '~/src/Model/Page/PageThumbnail'
import HomepagePagesItem from '~/components/Homepage/Components/HomepagePagesItem.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'

export default defineComponent({
  components: { HomepagePagesItem, Row },
  props: {
    promotedPages: {
      required: true,
      type: Array as PropType<PageThumbnail[]>
    },
    interestingPages: {
      required: true,
      type: Array as PropType<PageThumbnail[]>
    }
  },

  setup (props) {
    const maximumVisiblePages = 8

    const pages = computed<PageThumbnail[]>(() => {
      const pages = props.promotedPages.concat(props.interestingPages)
      return pages.slice(0, maximumVisiblePages)
    })

    return {
      pages
    }
  }
})
