
































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { CompanySearchAddress } from '~/src/Model/CompanySearch/CompanySearch'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: { Alert },
  props: {
    suggestedAddress: {
      type: Object as PropType<CompanySearchAddress | null>,
      default: null
    }
  },
  setup (props) {
    const addressExists = computed<boolean>(() => {
      if (props.suggestedAddress === null) {
        return false
      }

      return Object.values(props.suggestedAddress).some(value => value !== null)
    })

    return {
      addressExists
    }
  }
})
