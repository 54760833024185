import { ProductPriceBundle } from '~/src/Model/ProductPriceBundle/ProductPriceBundle'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useProductPriceBundlesRepository = () => {
  const { $axios } = useAxios()

  return {
    loadProductPriceBundle: (id: number): Promise<ProductPriceBundle> => $axios.$get<ProductPriceBundle>(`/product-price-bundles/${id}`)
  }
}
