import { computed } from '@nuxtjs/composition-api'
import { isAfter } from 'date-fns'
import { useProductDetailsRepository } from '~/src/Infrastructure/ProductDetail/ProductDetailsRepository'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { ProductAvailabilityModalRequest } from '~/src/Model/Product/ProductAvailabilityModal'
import { bigify } from '~/src/Infrastructure/Number/Bigify/Bigify'
import {
  useChristmasDeliveryConfigurationStore
} from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryConfigurationStore'

export const useProductAvailabilityModal = () => {
  const christmasDeliveryConfigurationStore = useChristmasDeliveryConfigurationStore()

  const { loadModalAvailability } = useProductDetailsRepository()

  const {
    exec: loadAvailability,
    loading,
    result: availability
  } = usePromise((productId: number, request: ProductAvailabilityModalRequest) => loadModalAvailability(productId, request))

  const pickupPlacesLowestPrice = computed<string>(() => bigify(Math.min(...availability.value?.pickupPlaces.pickupPlaces.map(p => p.price) ?? [0])).toString())
  const pickupPlacesSamePrice = computed<boolean>(() => availability.value?.pickupPlaces.pickupPlaces.every(p => bigify(p.price).eq(pickupPlacesLowestPrice.value)) ?? false)
  const pickupPlacesSameDeliveryDate = computed<boolean>(() => availability.value?.pickupPlaces.pickupPlaces.every(p => availability.value?.pickupPlaces.pickupPlaces[0].deliveryDate === p.deliveryDate) ?? false)
  const pickupPlacesSameDeliveryDateString = computed<string | null>(() => availability.value?.pickupPlaces.pickupPlaces[0]?.deliveryDate ?? null)
  const pickupPlacesSameDeliveryDateMessage = computed<string>(() => availability.value?.pickupPlaces.pickupPlaces[0]?.deliveryDateInfo.message ?? '')
  const pickupPlacesNamesString = computed<string>(() => availability.value ? availability.value.pickupPlaces.pickupPlaces.map(p => p.shortName).join(', ') : '')
  const pickupPlacesSomeAfterDeadline = computed<boolean>(() => availability.value?.pickupPlaces.pickupPlaces.some((p) => {
    if (!christmasDeliveryConfigurationStore.christmasDeliveryConfiguration.value) {
      return false
    }
    const deadline = christmasDeliveryConfigurationStore.christmasDeliveryConfiguration.value.find(config => config.uid === p.uid)?.deadline
    return deadline ? isAfter(Date.now(), new Date(deadline)) : false
  }) ?? false)
  const pickupPlacesAllAfterDeadline = computed<boolean>(() => {
    if (!christmasDeliveryConfigurationStore.christmasDeliveryConfiguration.value || !availability.value || !pickupPlacesSomeAfterDeadline.value) {
      return false
    }
    const pickupPlacesUids = availability.value.pickupPlaces.pickupPlaces.map(p => p.uid)
    return christmasDeliveryConfigurationStore.christmasDeliveryConfiguration.value.filter(config => pickupPlacesUids.includes(config.uid)).every(config => isAfter(Date.now(), new Date(config.deadline)))
  })

  return {
    availability,
    loadAvailability,
    loading,
    pickupPlacesSomeAfterDeadline,
    pickupPlacesAllAfterDeadline,
    pickupPlacesSamePrice,
    pickupPlacesLowestPrice,
    pickupPlacesSameDeliveryDate,
    pickupPlacesNamesString,
    pickupPlacesSameDeliveryDateString,
    isActiveChristmas: christmasDeliveryConfigurationStore.isActiveChristmas,
    christmasDeliveryConfiguration: christmasDeliveryConfigurationStore.christmasDeliveryConfiguration,
    pickupPlacesSameDeliveryDateMessage
  }
}
