





























































import { computed, defineComponent, onMounted, PropType, ref } from '@nuxtjs/composition-api'
import { ValidationResult } from 'vee-validate/dist/types/types'
import BaseValidationProvider from '~/components/Forms/Validation/BaseValidationProvider.vue'
import { RadioGroupOption } from '~/src/Model/Form/Input/RadioGroupOption'
import FormRadio from '~/components/DesignSystem/Input/FormRadio.vue'
import Tooltip from '~/components/DesignSystem/Tooltip/Tooltip.vue'

export default defineComponent({
  components: {
    BaseValidationProvider,
    FormRadio,
    Tooltip
  },
  props: {
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array as PropType<RadioGroupOption[]>,
      required: true
    },
    value: {
      type: [Number, String, Boolean],
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    rules: {
      type: String,
      default: null
    },
    labelClass: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: 'bs-mb-1'
    },
    isComplex: {
      type: Boolean,
      default: false
    },
    hasHover: {
      type: Boolean,
      default: false
    },
    isRadioProgress: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const required = ref<boolean>(props.rules.includes('required'))

    const validationProvider = ref()
    const providerRef = computed(() => validationProvider.value.$refs.providerRef)

    onMounted(() => {
      providerRef.value.syncValue(props.value)
    })

    const inputValue = computed<string | number | boolean>({
      get: () => props.value,
      set: async (newValue: string | number | boolean) => {
        providerRef.value.syncValue(newValue)
        const validationResult: ValidationResult = await providerRef.value.validate(newValue)

        if (validationResult.valid) {
          emit('input', newValue)
        }
      }
    })

    const isChecked = (option: RadioGroupOption) => {
      return props.value === option.value
    }

    return {
      isChecked,
      required,
      inputValue,
      validationProvider
    }
  }
})
