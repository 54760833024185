import { Ref, watch } from '@nuxtjs/composition-api'
import { idleCallback } from '~/src/Infrastructure/WebAPI/IdleCallback'

export const useDataLayerOnPageLoadedExecution = (callback: Function, pageLoaded: Ref<boolean>) => {
  watch(pageLoaded, () => {
    if (pageLoaded.value) {
      idleCallback(() => callback())
    }
  }, { immediate: true })
}
