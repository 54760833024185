





































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import LoyaltyProductBox from '~/components/ProductBox/LoyaltyProductBox.vue'
import ProductSlider from '~/components/ProductSlider/ProductSlider.vue'
import { LoyaltyProducts } from '~/src/Model/LoyaltyProducts/LoyaltyProducts'
import { createEmptyProductBox } from '~/src/Model/ProductBox/ProductBox'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

export default defineComponent({
  components: {
    LoyaltyProductBox,
    ProductSlider,
    Button
  },
  props: {
    loyaltyProducts: {
      default: null,
      type: Object as PropType<LoyaltyProducts | null>
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  setup () {
    return { createEmptyProductBox }
  }
})
