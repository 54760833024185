



























































import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { ProductBox } from '~/src/Model/ProductBox/ProductBox'
import MinimalPriceBundle from '~/components/ProductBox/Components/Price/MinimalPriceBundle.vue'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import ProductAvailabilityModal from '~/components/ProductAvailability/ProductAvailabilityModal/ProductAvailabilityModal.vue'
import PreBasketSidebar from '~/components/Basket/PreBasket/PreBasketSidebar.vue'
import { BasketProduct } from '~/src/Model/Basket/Product'
import { useBasketInsertProduct } from '~/src/Infrastructure/Basket/BasketInsert'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import TextSkeleton from '~/components/DesignSystem/Skeleton/TextSkeleton.vue'

const ReservationModal = () => ({
  component: import('~/components/Reservation/ReservationModal.vue')
})

export default defineComponent({
  components: {
    ReservationModal,
    PreBasketSidebar,
    ProductAvailabilityModal,
    ImageWithFallback,
    MinimalPriceBundle,
    TextSkeleton
  },
  props: {
    productBox: {
      required: true,
      type: Object as PropType<ProductBox>
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const showAvailabilityModal = ref<boolean>(false)
    const basketProduct = ref<BasketProduct | null>(null)
    const preselectedStore = ref<number | null>(null)
    const { basketInsertProduct, basketInsertProductPending } = useBasketInsertProduct()
    const { isLoyaltyMember } = useAuthenticatedUser()

    const onlyReservation = computed<boolean>(() => {
      if (!props.productBox.purchaseAvailability) {
        return false
      }

      return !props.productBox.purchaseAvailability.order.isAllowed && props.productBox.purchaseAvailability.reservation.isAllowed
    })

    const addToBasket = async () => {
      basketProduct.value = await basketInsertProduct(props.productBox.product.id)
    }

    const reserve = (storeId: number) => {
      preselectedStore.value = storeId
    }

    const openAvailabilityModal = () => {
      showAvailabilityModal.value = true
    }

    return {
      isLoyaltyMember,
      onlyReservation,
      showAvailabilityModal,
      openAvailabilityModal,
      addToBasket,
      basketInsertProductPending,
      basketProduct,
      reserve,
      preselectedStore
    }
  }
})
