import { useFetch } from '@nuxtjs/composition-api'
import { useOrderRepositoryWithAxios } from '~/src/Infrastructure/Order/OrderRepository'
import { useSafePromise } from '~/src/Infrastructure/Api/Promise'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useOrderDetailFetch = (orderNumber: number, orderToken: string | null = null) => {
  const { $axios } = useAxios()

  const { loadOrder } = useOrderRepositoryWithAxios($axios)
  const {
    result: order,
    error,
    exec
  } = useSafePromise(() => loadOrder(orderNumber, orderToken))

  useFetch(async () => {
    await exec()
  })

  return {
    order,
    error
  }
}
