


































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { PurchasedProduct, PurchaseInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import PurchaseDetailGift from '~/components/Profile/Purchases/PurchaseDetailGift.vue'
import { AfterSaleParentPurchase, OrderGiftItem, OrderProductItem } from '~/src/Model/Order/Order'
import PurchaseDetailProduct from '~/components/Profile/Purchases/PurchaseDetailProduct/PurchaseDetailProduct.vue'
import NewReclamationCrossroadModal from '~/components/Reclamation/NewReclamationCrossroad/NewReclamationCrossroadModal.vue'

export default defineComponent({
  name: 'PurchaseDetailProductWithGifts',
  components: { PurchaseDetailGift, PurchaseDetailProduct, NewReclamationCrossroadModal },
  props: {
    product: {
      type: Object as PropType<OrderProductItem>,
      required: true
    },
    scaffoldPurchaseInfo: {
      type: Object as PropType<PurchaseInfo>,
      required: true
    },
    afterSaleParentPurchase: {
      type: Object as PropType<AfterSaleParentPurchase | null>,
      default: null
    }
  },
  setup (props) {
    const purchasedProduct = ref<PurchasedProduct | null>(null)

    const showReclamationModal = (gift?: OrderGiftItem): void => {
      purchasedProduct.value = {
        product: gift ? gift.product : props.product.product,
        purchaseInfo: props.scaffoldPurchaseInfo,
        reclamation: gift ? gift.reclamation : props.product.reclamation
      } as PurchasedProduct
    }
    return {
      showReclamationModal,
      purchasedProduct
    }
  }
})
