














import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    hasSmallTitle: {
      type: Boolean,
      default: false
    }
  }
})
