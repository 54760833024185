























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import PurchaseDetailProductBundleItem from '~/components/Profile/Purchases/PurchaseDetailProductBundle/PurchaseDetailProductBundleItem.vue'
import { PurchaseBundleInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import { OrderProductBundle } from '~/src/Model/Order/Order'

export default defineComponent({
  components: { PurchaseDetailProductBundleItem },
  props: {
    productBundle: {
      type: Object as PropType<OrderProductBundle>,
      required: true
    },
    purchaseBundleInfo: {
      type: Object as PropType<PurchaseBundleInfo>,
      required: true
    }
  },
  setup (props) {
    const productBundleProductsTypes = computed<string>(() => {
      return props.productBundle.items.map(p => p.product?.typeName).join(' + ')
    })

    return {
      productBundleProductsTypes
    }
  }
})
