


































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { OrderGiftItem } from '~/src/Model/Order/Order'
import ProductNameLink from '~/components/Basket/Deprecated/ProductNameLink.vue'
import ProductImage from '~/components/Basket/Deprecated/ProductImage.vue'
import { PurchaseInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

export default defineComponent({
  components: {
    ProductNameLink,
    ProductImage,
    Row,
    Column,
    Button
  },
  props: {
    gift: {
      type: Object as PropType<OrderGiftItem>,
      required: true
    },
    purchaseInfo: {
      type: Object as PropType<PurchaseInfo>,
      required: true
    }
  },
  emits: ['show-reclamation']
})
