export enum StornoReason {
  OTHER_PRODUCT = 'OTHER_PRODUCT',
  BETTER_PRICE = 'BETTER_PRICE',
  NO_WAIT = 'NO_WAIT',
  UNSPECIFIED = 'UNSPECIFIED',
  OTHER = 'OTHER'
}

export interface StornoRequest {
  reason: string
  customerReason: string | null
}
