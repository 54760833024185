





































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import Tooltip from '~/components/DesignSystem/Tooltip/Tooltip.vue'
import PickupPointIcon from '~/components/PickupPoint/PickupPointIcon.vue'

export default defineComponent({
  components: { PickupPointIcon, Tooltip },
  props: {
    title: {
      type: String,
      default: ''
    },
    deliveryInfo: {
      type: String as PropType<string | null>,
      default: null
    },
    hasAdditionalInfo: {
      type: Boolean,
      default: false
    },
    tooltipMessage: {
      type: String,
      default: null
    },
    afterDeadline: {
      type: Boolean,
      default: false
    },
    treeIcon: {
      type: Boolean,
      default: false
    },
    deliveryUid: {
      type: String as PropType<string | null>,
      default: null
    },
    isActiveChristmas: {
      type: Boolean,
      default: false
    },
    overrideDeadlineMessage: {
      type: Boolean,
      default: false
    }
  }
})
