










































































import { defineComponent } from '@nuxtjs/composition-api'
import PurchaseDetailProduct from '~/components/Profile/Purchases/PurchaseDetailProduct/PurchaseDetailProduct.vue'
import { useReceiptFetch } from '~/src/Infrastructure/Receipt/ReceiptFetch'
import SectionTitle from '~/components/Order/SectionTitle/SectionTitle.vue'
import { PurchaseInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import { Type } from '~/src/Model/Purchase/Purchase'
import PrintLink from '~/components/PrintLink/PrintLink.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: {
    PurchaseDetailProduct,
    SectionTitle,
    PrintLink,
    Row,
    Column,
    Alert
  },
  props: {
    receiptNumber: {
      required: true,
      type: Number
    }
  },
  setup (props) {
    const { receipt } = useReceiptFetch(props.receiptNumber)

    const scaffoldPurchaseInfo = (productId: number): PurchaseInfo => {
      return {
        number: props.receiptNumber,
        type: Type.Receipt,
        itemId: productId,
        purchaseDate: receipt.value?.created ?? ''
      }
    }

    return {
      receipt,
      scaffoldPurchaseInfo
    }
  }
})
