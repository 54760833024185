






















































import { defineComponent } from '@nuxtjs/composition-api'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

export default defineComponent({
  components: {
    Button
  },
  props: {
    orderAllowed: {
      type: Boolean,
      required: true
    },
    reservationAllowed: {
      type: Boolean,
      required: true
    },
    isPreorder: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    purchaseAvailabilityMessage: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  },
  emits: ['add-to-basket', 'reserve'],
  setup () {
    return {
    }
  }
})
