import { Image } from '~/src/Model/Image/Image'

export interface ArticleCategory {
  id: number
  name: string
  alias: string
}

export interface ArticleTag {
  id: number
  name: string
}

export interface ArticleThumbnail {
  id: number
  name: string
  alias: string
  image: Image | null
  publishedDate: string
  categories: ArticleCategory[]
  tags: ArticleTag[]
  description: string | null
  readTime: number
}

export interface Magazine {
  category: ArticleCategory
  topCategories: ArticleCategory[]
  topArticle: ArticleThumbnail | null
  articles: ArticleThumbnail[]
  totalCount: number
}

export const createEmptyArticleThumbnail = (): ArticleThumbnail => {
  return {
    id: 1,
    name: '',
    alias: '#',
    image: null,
    publishedDate: '2020-07-04 10:00:00',
    description: null,
    readTime: 0,
    tags: [],
    categories: [{
      id: 1,
      name: '',
      alias: '#'
    }]
  } as ArticleThumbnail
}
