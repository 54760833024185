import { Product } from '~/src/Model/Product/Product'
import { OrderStatus } from '~/src/Model/Order/Order'

export enum Type {
  Order = 'order',
  Reservation = 'reservation',
  Receipt = 'receipt'
}

export interface PurchaseDeliveryTimeSlot {
  date: string
  from: string
  to: string
}

export interface PurchaseDelivery {
  name: string
  branchName: string | null
  timeSlot: PurchaseDeliveryTimeSlot | null
}

export interface Purchase {
  purchaseId: number
  status: OrderStatus
  number: number
  paid: boolean
  type: Type
  created: string
  totalSum: string
  deliveryName: string
  delivery: PurchaseDelivery | null
  payMethodName: string
  serviceOffersAvailable: boolean
  reviewAvailable: boolean
  products: Product[]
  canBeCancelled: boolean | null
}

export interface CustomerPurchases {
  purchases: Purchase[]
  totalCount: number
}
