





















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    price: {
      type: String,
      default: null
    },
    showPriceFromLang: {
      type: Boolean,
      default: false
    },
    listClass: {
      type: String,
      default: null
    }
  }
})
