


















































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import VerticalSteps from '~/components/VerticalSteps/VerticalSteps.vue'
import VerticalStepTitle from '~/components/VerticalSteps/VerticalStepTitle.vue'
import VerticalStepsItem from '~/components/VerticalSteps/VerticalStepsItem.vue'
import VerticalStepBodyText from '~/components/VerticalSteps/VerticalStepBodyText.vue'
import { Type } from '~/src/Model/Purchase/Purchase'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import CopyButton from '~/components/DesignSystem/Buttons/CopyButton.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

export default defineComponent({
  components: {
    VerticalSteps,
    VerticalStepTitle,
    VerticalStepsItem,
    VerticalStepBodyText,
    Button,
    CopyButton,
    PageSectionContent
  },
  props: {
    purchaseType: {
      required: true,
      type: String as PropType<Type>
    },
    purchaseNumber: {
      type: Number,
      required: true
    }
  },
  emits: ['cancel'],
  setup () {
    return {
      ORDER_PURCHASE_TYPE: Type.Order
    }
  }
})
