


















































import { defineComponent, PropType, computed, watch, ref, onMounted, onUnmounted } from '@nuxtjs/composition-api'
import { SimpleEventDispatcher } from 'ste-simple-events'
import { filterWhitespaces } from '~/src/Infrastructure/String/FilterWhiteSpaces'
import { useSafePromise } from '~/src/Infrastructure/Api/Promise'
import { usePostCodesRepository } from '~/src/Infrastructure/PostCodes/PostCodesRepository'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import TextSkeleton from '~/components/DesignSystem/Skeleton/TextSkeleton.vue'

export default defineComponent({
  components: { FormInput, TextSkeleton },
  props: {
    invalidZip: {
      type: Boolean,
      default: null
    },
    value: {
      type: String as PropType<string | null>,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      required: true,
      type: String
    },
    invalidZipMessage: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    postCodeCheck: {
      type: Boolean,
      default: true
    },
    hidePositiveState: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    button: {
      type: Boolean,
      default: false
    },
    disableAutoExecution: {
      type: Boolean,
      default: false
    },
    checkEventDispatcher: {
      type: Object as PropType<SimpleEventDispatcher<void>|null>,
      default: null
    },
    wrapClass: {
      type: String,
      default: null
    },
    labelClass: {
      type: String,
      default: null
    },
    inputWrapClass: {
      type: String,
      default: null
    },
    feedbackClass: {
      type: String,
      default: null
    },
    showSpinner: {
      type: Boolean,
      default: false
    },
    showSkeleton: {
      type: Boolean,
      default: false
    },
    forceFocus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    checkImmediately: {
      type: Boolean,
      default: false
    },
    zipNotFoundMessage: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const { loadPostCodes } = usePostCodesRepository()
    const postCodeLoaded = ref<boolean>(false)
    const lastSubmittedPostCode = ref<string>('')
    const isFocused = ref<boolean>(false)

    const localValue = computed<string | null>({
      get: () => props.value,
      set: value => emit('input', value)
    })

    const showZipNotFoundMessage = computed<boolean>(() => {
      if (!localValue.value) {
        return false
      }

      return postCodeLoaded.value && lastSubmittedPostCode.value === filterWhitespaces(localValue.value!)
    })

    const { exec, result: postCode, onCompleted, loading: postCodeCheckPending } = useSafePromise((zip: string) => loadPostCodes(zip))

    onCompleted.subscribe(() => {
      postCodeLoaded.value = true
    })

    onMounted(async () => {
      if (props.checkEventDispatcher) {
        props.checkEventDispatcher.subscribe(checkZipValue)
      }

      if (props.checkImmediately) {
        await checkZipValue()
      }
    })

    onUnmounted(() => {
      if (props.checkEventDispatcher) {
        props.checkEventDispatcher.unsubscribe(checkZipValue)
      }
    })

    watch(localValue, async () => {
      if (props.disableAutoExecution) {
        return
      }

      if (!props.postCodeCheck) {
        return
      }

      await checkZipValue()
    })

    const checkZipValue = async () => {
      if (!localValue.value) {
        return
      }

      const zip = filterWhitespaces(localValue.value)
      if (!/^[0-9]{5}$/.test(zip)) {
        return
      }

      await exec(zip)

      lastSubmittedPostCode.value = zip
    }

    return {
      localValue,
      postCode,
      postCodeLoaded,
      filterWhitespaces,
      isFocused,
      lastSubmittedPostCode,
      postCodeCheckPending,
      showZipNotFoundMessage
    }
  }
})
