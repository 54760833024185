














































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import LostPasswordPanel from '~/components/Sidebar/LostPasswordPanel.vue'
import SidebarRegistrationPanel from '~/components/Sidebar/SidebarRegistrationPanel.vue'
import { defaultForceLoginEmail, ForceLoginEmail } from '~/src/Model/CheckCompanyCustomer/CheckCompanyCustomer'
import LoginPanelCrossroad from '~/components/Auth/Karpaty/LoginPanelCrossroad.vue'

export default defineComponent({
  components: { LoginPanelCrossroad, SidebarRegistrationPanel, LostPasswordPanel },
  props: {
    showRegistrationLink: {
      type: Boolean,
      default: false
    },
    forceLoginState: {
      type: Object as PropType<ForceLoginEmail>,
      default: () => defaultForceLoginEmail
    },
    dataLayerFormId: {
      type: String,
      default: null
    }
  },
  emits: ['logged', 'registered'],
  setup (_props, { emit }) {
    const showLostPasswordPanel = ref<boolean>(false)
    const showRegistrationPanel = ref<boolean>(false)

    const hideSidebar = (): void => {
      showLostPasswordPanel.value = false
      showRegistrationPanel.value = false
    }

    const registered = () => {
      showRegistrationPanel.value = false
      emit('registered')
    }

    return {
      registered,
      showLostPasswordPanel,
      hideSidebar,
      showRegistrationPanel
    }
  }
})
