




















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    border: {
      type: Boolean,
      default: false
    },
    displayClass: {
      type: String,
      default: 'd-flex'
    },
    padding: {
      type: String,
      default: 'bs-px-3 bs-py-4 bs-p-md-4'
    },
    tag: {
      type: String,
      default: 'div'
    }
  },

  setup () {
  }
})
