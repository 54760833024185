import { Product } from '~/src/Model/Product/Product'
import { ProductBoxExtendedPriceBundle, ProductBoxPriceBundle } from '~/src/Model/ProductPriceBundle/ProductPriceBundle'
import { AverageReview } from '~/src/Model/AverageReview/AverageReview'
import { ProductParameter } from '~/src/Model/ProductDetail/ProductParameters/ProductParameter'
import { SpecialOffer } from '~/src/Model/SpecialOffers/SpecialOffers'
import { ProductTag } from '~/src/Model/ProductTag/ProductTag'
import { ProductAvailability } from '~/src/Model/ProductAvailability/ProductAvailability'
import { ProductOptionalDiscount } from '~/src/Model/ProductOptionalDiscount/ProductOptionalDiscount'
import { EnergyLabel } from '~/src/Model/EnergyLabel/EnergyLabel'
import { PurchaseAvailability } from '~/src/Model/PurchaseAvailability/PurchaseAvailability'

export interface ProductBox {
  product: Product
  priceBundle: ProductBoxPriceBundle | ProductBoxExtendedPriceBundle
  averageReview: AverageReview
  topParameters: ProductParameter[]
  specialOffers: SpecialOffer[]
  tags: ProductTag[]
  productAvailability: ProductAvailability
  optionalDiscounts: ProductOptionalDiscount[]
  energyLabel: EnergyLabel | null
  purchaseAvailability: PurchaseAvailability
  parametricAnnotation: string | null
}

export interface ProductBoxes {
  productBoxes: ProductBox[]
}

export const createEmptyProductBox = (): ProductBox => {
  return {
    product: {
      id: 1,
      name: '',
      alias: '#',
      image: null,
      typeName: '',
      isStoreModeProduct: false,
      type: {
        name: '',
        isDigitalContent: false,
        isGiftVoucher: false,
        isPhysicalProduct: true
      }
    },
    priceBundle: {
      sellPrice: {
        amount: '0',
        priceLevel: 'NORMAL',
        isLoyaltyPrice: false,
        isB2BPrice: false,
        isEmployeePrice: false,
        isSalePrice: false
      },
      oldPrice: {
        amount: '0',
        priceLevel: 'RRP',
        discount: {
          amount: '0',
          percentage: '0'
        }
      },
      loyaltyPrice: {
        amount: '0',
        priceLevel: 'ZES1',
        discount: {
          amount: '0',
          percentage: '0'
        }
      },
      rrpPrice: {
        amount: '0',
        priceLevel: 'RRP'
      }
    }
  } as ProductBox
}
