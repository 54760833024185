import { ref, useFetch } from '@nuxtjs/composition-api'
import { useRecommendedProductsRepository } from '~/src/Infrastructure/RecommendedProducts/RecommendedProductsRepository'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { ProductBox } from '~/src/Model/ProductBox/ProductBox'

export const useRecommendedProductsFetch = (id: number, limit: number, lazy: boolean = false, ignoredAvailability: boolean | null = null) => {
  const currentPage = ref<number>(1)
  const recommendedProducts = ref<ProductBox[]>([])
  const clearFetch = ref<boolean>(true)
  const totalCount = ref<number>(0)

  const { loadRecommendedProducts } = useRecommendedProductsRepository()

  const { exec, loading } = usePromise(async () => {
    const response = await loadRecommendedProducts(id, limit, currentPage.value, ignoredAvailability)
    if (clearFetch.value) {
      recommendedProducts.value = response.recommendedProducts
    } else {
      recommendedProducts.value.push(...response.recommendedProducts)
    }

    totalCount.value = response.totalCount
  })

  useFetch(async () => {
    if (!lazy) {
      await exec()
    }
  })

  const loadPage = async (page: number) => {
    currentPage.value = page
    clearFetch.value = true
    await exec()
  }

  const loadMore = async () => {
    currentPage.value++
    clearFetch.value = false
    await exec()
  }

  return {
    recommendedProducts,
    loading,
    loadPage,
    loadMore,
    totalCount,
    loadRecommendedProducts: exec
  }
}
