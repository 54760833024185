import { PostCode } from '~/src/Model/PostCode/PostCode'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const usePostCodesRepository = () => {
  const { $axios } = useAxios()

  return {
    loadPostCodes: (postCodeNumber: string, showProgress: boolean = false): Promise<PostCode> => $axios.$get<PostCode>(`/post-codes/${postCodeNumber}`, { progress: showProgress })
  }
}
