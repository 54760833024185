











import { PropType } from '@nuxtjs/composition-api'
import { Order } from '~/src/Model/Order/Order'

export default {
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    }
  }
}
