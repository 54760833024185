






































import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { ProductBox } from '~/src/Model/ProductBox/ProductBox'
import MinimalProductBox from '~/components/ProductBox/MinimalProductBox.vue'
import ProductAvailabilityEshop from '~/components/ProductAvailability/ProductAvailabilityEshop.vue'
import BuyDigitalProductModal from '~/components/ProductDetail/DigitalProduct/BuyDigitalProductModal.vue'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import BuyGiftVoucherProductModal from '~/components/ProductDetail/GiftVoucherProduct/BuyGiftVoucherProductModal.vue'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'

export default defineComponent({
  name: 'ProductAccessorySliderItem',
  components: {
    BuyGiftVoucherProductModal,
    ProductAvailabilityEshop,
    MinimalProductBox,
    BuyDigitalProductModal,
    Button
  },
  props: {
    productBox: {
      type: Object as PropType<ProductBox>,
      required: true
    },
    addToBasketPending: {
      type: Boolean,
      default: false
    },
    showBuyButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ['product-added'],
  setup (props, { emit }) {
    const basketStore = useBasketStore()
    const { i18n } = useTranslator()

    const isProductInBasket = computed<boolean>(() => basketStore.isProductInBasket(props.productBox.product.id))
    const showBuyDigitalProductModal = ref<boolean>(false)
    const showBuyGiftVoucherProductModal = ref<boolean>(false)

    const addToBasket = () => {
      emit('product-added', props.productBox.product.id)
    }

    const buyButtonClick = () => {
      if (props.productBox.product.type?.isDigitalContent) {
        showBuyDigitalProductModal.value = true
        return
      }

      if (props.productBox.product.type?.isGiftVoucher) {
        showBuyGiftVoucherProductModal.value = true
        return
      }

      addToBasket()
    }

    const buyButtonText = computed<string>(() => {
      if (props.productBox.product.type?.isDigitalContent) {
        return i18n.t('product_box.availability.buy_digital_short') as string
      }

      if (props.productBox.product.type?.isGiftVoucher) {
        return i18n.t('product_box.availability.buy_gift_voucher_short') as string
      }

      return i18n.t('nayeshop_product_accessories.modal.add_to_basket') as string
    })

    const buyButtonDisabled = computed<boolean>(() => {
      if (props.productBox.product.type?.isDigitalContent || props.productBox.product.type?.isGiftVoucher) {
        return !props.productBox.purchaseAvailability.digitalContent.isAllowed
      }

      return !props.productBox.purchaseAvailability.order.isAllowed
    })

    return {
      isProductInBasket,
      showBuyDigitalProductModal,
      showBuyGiftVoucherProductModal,
      addToBasket,
      buyButtonClick,
      buyButtonText,
      buyButtonDisabled
    }
  }
})
