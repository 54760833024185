





























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import MinimalProductBox from '~/components/ProductBox/MinimalProductBox.vue'
import ProductSlider from '~/components/ProductSlider/ProductSlider.vue'
import { createEmptyProductBox, ProductBox } from '~/src/Model/ProductBox/ProductBox'

export default defineComponent({
  components: {
    MinimalProductBox,
    ProductSlider
  },
  props: {
    recommendedProducts: {
      default: () => [],
      type: Array as PropType<ProductBox[]>
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  setup () {
    return { createEmptyProductBox }
  }
})
