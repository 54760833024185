






















































import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import { Purchase, Type } from '~/src/Model/Purchase/Purchase'
import PurchaseDetailModal from '~/components/Profile/Purchases/PurchaseDetailModal.vue'
import ReceiptDetailModal from '~/components/Profile/Purchases/ReceiptDetailModal.vue'
import { useCustomerPurchasesFetch } from '~/src/Infrastructure/CustomerPurchases/CustomerPurchasesFetch'
import { emptyFilterParameters } from '~/src/Infrastructure/CustomerPurchases/CustomerPurchasesRepository'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import PageSection from '~/components/DesignSystem/PageSection/PageSection.vue'

export default defineComponent({
  components: {
    PurchaseDetailModal,
    ReceiptDetailModal,
    Button,
    Alert,
    PageSection
  },
  props: {
    customerId: {
      required: true,
      type: Number
    }
  },
  setup (props) {
    const showPurchaseModal = ref<boolean>(false)
    const showReceiptModal = ref<boolean>(false)
    const { authenticatedUser } = useAuthenticatedUser()

    const { purchases, forceReload } = useCustomerPurchasesFetch(props.customerId, {
      ...emptyFilterParameters(),
      page: 1,
      limit: 1,
      onlyActive: 1,
      excludeActive: 0
    })

    const purchase = computed<Purchase|null>(() => {
      if (purchases.value.length > 0) {
        return purchases.value[0]
      }

      return null
    })

    const openModal = () => {
      if (!purchase.value) {
        return
      }

      if (purchase.value.type === Type.Receipt) {
        showReceiptModal.value = true
        return
      }

      showPurchaseModal.value = true
    }

    return {
      showPurchaseModal,
      showReceiptModal,
      openModal,
      purchase,
      authenticatedUser,
      forceReload
    }
  }
})
