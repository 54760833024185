import { MagazineLatestArticles } from '~/src/Model/MagazineLatestArticles/MagazineLatestArticles'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useMagazineLatestArticlesRepository = () => {
  const { $axios } = useAxios()

  return {
    getArticles: (): Promise<MagazineLatestArticles> => $axios.$get<MagazineLatestArticles>('/magazine-latest-articles')
  }
}
