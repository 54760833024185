











import { defineComponent } from '@nuxtjs/composition-api'
import Column from '~/components/DesignSystem/Grid/Column.vue'

export default defineComponent({
  components: { Column }
})
