import { ref, useFetch } from '@nuxtjs/composition-api'
import { Receipt } from '~/src/Model/Receipt/Receipt'
import { useReceiptRepository } from '~/src/Infrastructure/Receipt/ReceiptRepository'

export const useReceiptFetch = (number: number) => {
  const receipt = ref<Receipt | null>(null)
  const { loadReceipt } = useReceiptRepository()

  useFetch(async () => {
    receipt.value = await loadReceipt(number)
  })

  return {
    receipt
  }
}
