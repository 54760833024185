import { Image } from '~/src/Model/Image/Image'

export enum PayMethodType {
  installmentsBridge = 'installmentsBridge',
  cash = 'cash',
  cashOnDelivery = 'cashOnDelivery',
  bankTransfer = 'bankTransfer',
  installment = 'installment',
  homeCredit = 'homeCredit',
  online = 'online'
}

export interface OrderPayMethod {
  name: string
  price: string
  type: PayMethodType
}

export interface PayMethod {
  id: number
  name: string
  description: string | null
  price: string
  extId: string | null
  type: PayMethodType
  image: Image | null
}

export interface ReservationPayMethods {
  payMethods: PayMethod[]
}

export interface PayMethodGroup {
  name: string
  description: string | null
  payMethods: PayMethod[]
  isSingleItemGroup: boolean
}

export interface BasketPayMethodsResponse {
  payMethodGroups: PayMethodGroup[]
}
