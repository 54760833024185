import { HomePage } from '~/src/Model/HomePage/HomePage'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useHomePageRepository = () => {
  const { $axios } = useAxios()

  return {
    loadHomePage: (): Promise<HomePage> => $axios.$get<HomePage>('/home-page')
  }
}
