





































import { defineComponent, ref, watch } from '@nuxtjs/composition-api'
import LayoutOrder from '~/components/DesignSystem/Layout/LayoutOrder.vue'
import ModalCheckoutProductBox from '~/components/ProductBox/ModalCheckoutProductBox.vue'
import { PayMethod } from '~/src/Model/Order/Delivery/PayMethod/PayMethod'
import { useDigitalProductPayMethodFetch } from '~/src/Infrastructure/DigitalProductPayMethods/DigitalProductPayMethodsFetch'
import {
  DigitalProductOrderCustomerInfo,
  DigitalProductOrderRequest,
  emptyDigitalProductCustomerFormInfo
} from '~/src/Model/DigitalProductOrder/DigitalProductOrder'
import { useDigitalProductOrderRequestFactory } from '~/src/Model/DigitalProductOrder/DigitalProductOrderRequestFactory'
import { useProductBoxesFetch } from '~/src/Infrastructure/ProductBoxes/ProductBoxesFetch'
import SpecialProductPayMethodSelect
  from '~/components/ProductDetail/SpecialProductCheckoutModal/SpecialProductPayMethodSelect.vue'
import GiftVoucherProductCustomerInfo
  from '~/components/ProductDetail/GiftVoucherProduct/GiftVoucherProductCustomerInfo.vue'
import { createDigitalOrderCustomerFormInfo } from '~/src/Model/DigitalProductOrder/DigitalProductOrderCustomerInfoFactory'
import SpecialProductConditionsAgreement
  from '~/components/ProductDetail/SpecialProductCheckoutModal/SpecialProductConditionsAgreement.vue'
import { useCustomerHelpers } from '~/src/Infrastructure/Customers/CustomerHelpers'
import { useCustomerInfoFetch } from '~/src/Infrastructure/Customers/CustomerInfoFetch'
import { CustomerInformation } from '~/src/Model/Customer/CustomerInformation'

export default defineComponent({
  components: {
    LayoutOrder,
    SpecialProductConditionsAgreement,
    GiftVoucherProductCustomerInfo,
    SpecialProductPayMethodSelect,
    ModalCheckoutProductBox
  },
  props: {
    productId: {
      required: true,
      type: Number
    },
    userId: {
      required: true,
      type: Number
    },
    isSubmitted: {
      type: Boolean,
      default: false
    }
  },
  emits: ['pay-method-selected', 'order-submitted'],
  setup (props, { emit }) {
    const { payMethods } = useDigitalProductPayMethodFetch()
    const selectedPayMethod = ref<PayMethod | null>(null)
    const customerInfo = ref<DigitalProductOrderCustomerInfo>(emptyDigitalProductCustomerFormInfo())
    const useCompanyData = ref<boolean>(false)
    const digitalProductOrderRequestFactory = useDigitalProductOrderRequestFactory()
    const { productBoxes } = useProductBoxesFetch([props.productId])
    const quantity = ref<number>(1)
    const { onCustomerInfoLoadedEvent } = useCustomerInfoFetch(props.userId)
    const { customerCountryCode } = useCustomerHelpers()

    onCustomerInfoLoadedEvent.subscribe((customerInformation: CustomerInformation) => {
      customerInfo.value = createDigitalOrderCustomerFormInfo(customerInformation, customerCountryCode)

      if (customerInfo.value.companyInfo) {
        useCompanyData.value = true
        return
      }

      customerInfo.value.companyInfo = emptyDigitalProductCustomerFormInfo().companyInfo
    })

    const submitReservation = () => {
      if (selectedPayMethod.value === null) {
        return
      }

      if (!useCompanyData.value) {
        customerInfo.value.companyInfo = null
        customerInfo.value.billingAddress = null
      }

      const request: DigitalProductOrderRequest = digitalProductOrderRequestFactory.create(
        props.productId,
        quantity.value,
        true,
        selectedPayMethod.value.id,
        customerInfo.value
      )

      emit('order-submitted', request)
    }

    watch(() => props.isSubmitted, (isSubmitted) => {
      if (isSubmitted) {
        submitReservation()
      }
    })

    return {
      payMethods,
      selectedPayMethod,
      customerInfo,
      useCompanyData,
      productBoxes,
      quantity
    }
  }
})
