import { Image } from '~/src/Model/Image/Image'

export interface Banner {
  bannerId: number
  name: string
  description: string | null
  backgroundColor: string | null
  imageDesktop: Image | null
  imageMobile: Image | null
  url: string
  openInNewWindow: boolean
}

export interface Banners {
  banners: Banner[]
}

export enum BannerPosition {
  ESHOP_BANNER_CATEGORY = 'ESHOP_BANNER_CATEGORY'
}

export const createEmptyBanners = (count: number) => {
  const banners = []

  for (let i = 0; i < count; i++) {
    banners.push({
      bannerId: i,
      name: '',
      description: '',
      backgroundColor: null,
      imageDesktop: null,
      imageMobile: null,
      url: '#',
      openInNewWindow: false
    })
  }

  return banners
}
