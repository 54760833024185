import { ref, useFetch } from '@nuxtjs/composition-api'
import { useLoyaltyProductsRepository } from '~/src/Infrastructure/LoyaltyProducts/LoyaltyProductsRepository'
import { LoyaltyProducts } from '~/src/Model/LoyaltyProducts/LoyaltyProducts'
import { usePromise } from '~/src/Infrastructure/Api/Promise'

export const useLoyaltyProductsFetch = (limit: number, page: number = 1, disableFetch: boolean = false) => {
  const loyaltyProducts = ref<LoyaltyProducts | null>(null)
  const { loadLoyaltyProducts } = useLoyaltyProductsRepository()

  const { exec, loading } = usePromise(async () => {
    loyaltyProducts.value = await loadLoyaltyProducts(limit, page)
  })

  useFetch(async () => {
    if (disableFetch) {
      return
    }
    await exec()
  })

  return {
    loyaltyProducts,
    loading,
    exec
  }
}
