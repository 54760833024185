
















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { OrderItemGeneric } from '~/src/Model/Order/Order'

export default defineComponent({
  props: {
    genericItem: {
      required: true,
      type: Object as PropType<OrderItemGeneric>
    },
    icon: {
      type: String,
      default: 'icon-percents'
    }
  }
})
