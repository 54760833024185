














import { defineComponent } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'

export default defineComponent({
  components: { ImageWithFallback },
  props: {
    alias: {
      default: null,
      type: String
    },
    image: {
      default: null,
      type: Object
    },
    name: {
      required: true,
      type: String
    }
  }
})
