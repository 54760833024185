import { Receipt, ReceiptImport } from '~/src/Model/Receipt/Receipt'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useReceiptRepository = () => {
  const { $axios } = useAxios()

  return {
    loadReceipt: (number: number): Promise<Receipt> => $axios.$get<Receipt>(`/receipts/${number}`),
    importReceipts: (): Promise<ReceiptImport> => $axios.$post<ReceiptImport>('/receipts/import')
  }
}
